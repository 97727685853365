import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import {
  Crud,
  CrudChangeType,
  CrudService,
} from '@shared/services/crud.service';
import { ForeignVisitor, Screening, ScreeningDisplayMap } from '../models';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Crud({
  apiUrl: `${environment.apiUrl}/screenings`,
  entity: 'Screening',
  hasIdPathUpdate: false,
})
export class ScreeningService extends CrudService<Screening> {
  statusMap = ScreeningDisplayMap;
  constructor(http: HttpClient) {
    super(http);
  }

  public exportScreenings(vip: boolean) {
    return vip ? this.exportVipScreening() : this.exportAllScreening();
  }

  findHistory(id: string) {
    return this.http.get(`${environment.apiUrl}/screeningHistory/${id}`);
  }
  findAllHistory() {
    this.http.get(`${environment.apiUrl}/screeningHistory`);
  }
  findHistoryByScreeningId(screeningId: string): Observable<Screening[]> {
    return this.http.get<Screening[]>(
      `${environment.apiUrl}/screeningHistory/ByScreeningId/${screeningId}`
    );
  }

  exportVipScreening(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${this.apiUrl}/exportFile/vip`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  exportAllScreening(): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${this.apiUrl}/exportFile/all`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  // override save(screening: Screening): Observable<ForeignVisitor | Screening> {
  //   if (screening.id) {
  //     return this.overrideScreening(screening);
  //   }
  //   return this.rescreen(screening.foreignVisitor!);
  // }

  /*The screening entity is unique.  The screening entity is only generated when
  1. A new Foreign Visitor is Created, the api will create a new screening entity
  2. When a rescreen action is requested for a foreign visitor
  */
  // TODO: Consider moving this functionallity to the FV Service. Even though it calls a Screening endpoint, it returns a FV object.
  /**
   * User rescreen when a User wants to initiate a new screening for a foreign visitor
   * @param fv the foreignvisitor that needs to be rescreened
   * @returns
   */
  rescreen(fv: Pick<ForeignVisitor, 'id'>) {
    const httpParams = new HttpParams({
      fromObject: { foreignVisitorId: fv.id },
    });
    return this.http
      .post<ForeignVisitor>(`${this.apiUrl}`, null, {
        context: this.context,
        params: httpParams,
      })
      .pipe(
        tap((fv) => {
          this.changeEvent$.emit({
            type: CrudChangeType.create,
            model: fv,
            modelId: fv.id,
          });
        })
      );
  }

  // LUKECIT-7480 - this is causing some difficulty with cross domain.  The override method only is available on J network, but needs to replicate to the U network.  The cross domain solution does not replicate new screening entities from high to low.
  overrideScreening(screening: any) {
    return this.http
      .post<Screening>(`${this.apiUrl}/override`, screening, {
        context: this.context,
      })
      .pipe(
        tap((fv) => {
          this.changeEvent$.emit({
            type: CrudChangeType.create,
            model: screening,
            modelId: screening.id,
          });
        })
      );
  }

  groupFVs(fvs: ForeignVisitor[]) {
    return { ...{}, ...this.groupbyScreening(fvs) };
  }

  filterFVs(unFilteredFvs: any, values: string[]): ForeignVisitor[] {
    let groupedFVs: ForeignVisitor[] = [];
    values.forEach((value) => {
      groupedFVs = groupedFVs.concat(unFilteredFvs[`${value}`]);
    });
    return groupedFVs;
  }

  rescreenFnByFar(farId: string) {
    let model = {
      ids: [farId],
      type: 'FAR',
    };
    return this.http.put<any>(`${this.apiUrl}/rescreen`, model);
  }

  rescreenFnByGroup(groupId: string) {
    let model = {
      ids: [groupId],
      type: 'FVG',
    };
    return this.http.put<any>(`${this.apiUrl}/rescreen`, model);
  }

  public findFvsMetrics(
    startDate: string,
    endDate: string,
    unit: string
  ): Observable<any> {
    let params = {
      startDate: startDate,
      endDate: endDate,
      interval: unit + '_OF_YEAR',
      byOrg: false,
    };
    return this.http.get<any>(`${this.apiUrl}/metricsByInterval`, {
      params: params,
    });
  }

  public findFvsMetricsByOrg(
    startDate: string,
    endDate: string,
    unit: string
  ): Observable<any> {
    let params = {
      startDate: startDate,
      endDate: endDate,
      interval: unit + '_OF_YEAR',
      byOrg: true,
    };
    return this.http.get<any>(`${this.apiUrl}/metricsByOrgInterval`, {
      params: params,
    });
  }

  // TODO: Why is this in the screening service? Should this go in the FV Service?
  groupbyScreening(fvs: ForeignVisitor[]): any {
    return fvs.reduce((acc: any, fv) => {
      if (fv && fv.latestScreening && fv.latestScreening.status) {
        const key = fv.latestScreening.status;
        const curGroup = acc[key] ?? [];
        acc = { ...acc, [key]: [...curGroup, fv] };
      }
      if (fv && fv.latestScreening && fv.latestScreening.displayResult) {
        const key = fv.latestScreening.displayResult;
        const curGroup = acc[key] ?? [];
        acc = { ...acc, [key]: [...curGroup, fv] };
      }
      return acc;
    }, {});
  }
}

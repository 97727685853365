import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FAR, ForeignVisitor, Group } from '@app/shared/models';
import { FarService, GroupService } from '@app/shared/services';
import { take } from 'rxjs';

@Component({
  selector: 'app-add-fvs-dialog',
  templateUrl: './add-fvs-dialog.component.html',
  styleUrls: ['./add-fvs-dialog.component.scss'],
})
export class AddFvsDialogComponent implements OnInit {
  fvsToAssociate: ForeignVisitor[] = [];
  id: string = '';
  organizationId: string = '';
  busy: boolean = false;
  ok: boolean = false;
  type: 'Group' | 'FAR';

  constructor(
    private farService: FarService,
    private groupService: GroupService,
    @Inject(MAT_DIALOG_DATA) private data: FAR | Group,
    private dialog: MatDialogRef<AddFvsDialogComponent>
  ) {}

  ngOnInit(): void {
    this.ok = false;
    this.id = this.data.id;

    if ((this.data as Group).foreignVisitorGroupType) this.type = 'Group';
    else this.type = 'FAR';

    if (this.data && this.data.ownerOrganizationId)
      this.organizationId = this.data.ownerOrganizationId;
  }

  onSubmit() {
    this.ok = true;
    this.busy = true;
    if (this.type === 'FAR') {
      this.farService
        .addFvToFar(this.data.id, this.fvsToAssociate)
        .pipe(take(1))
        .subscribe((far) => {
          this.onClose();
        });
    } else if (this.type === 'Group') {
      let idArray = [];
      for (let fv of this.fvsToAssociate) idArray.push(fv.id);
      this.groupService
        .addFvToGroup(this.data.id, idArray)
        .pipe(take(1))
        .subscribe((group) => {
          this.onClose();
        });
    }
  }
  onClose() {
    this.dialog.close();
  }
  onRemove(fv: ForeignVisitor) {
    const filtered = this.fvsToAssociate.filter((x) => {
      return fv.id != x.id;
    });
    this.fvsToAssociate = filtered;
  }
}

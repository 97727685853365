import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActionButton } from '@app/shared/models/actionbutton.model';
import { FilterParams } from '@app/shared/models/filter-params.model';
import { ForeignVisitor } from '@app/shared/models/foreign-visitor.model';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import {
  AppConfigService,
  FvSortFieldLabels,
  FvSorts,
} from '@app/shared/services';
import { FVEditRoles } from '@shared/models/role-permissions';
import { CollectionView, SearchToolbarComponent } from '@app/shared/components';
import { ScreeningResult, UserPreferenceBlob } from '@app/shared/models';
import { FilterConfig } from '@shared/components/advanced-search/filter-config';
import { FvAdvancedSearch } from '@app/foreign-visitors/dashboard/fv-advanced-search';
import {
  FVTableColumns,
  FVTableName,
} from '@app/foreign-visitors/dashboard/fv-table/fv-table.component';
import { FnGroupDialogComponent } from '../../fn-group-dialog/fn-group-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '@app/shared/services';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import { ViewService } from '@app/shared/services/view.service';
import { take, debounceTime } from 'rxjs';

const actionButtons: ActionButton[] = [
  {
    label: 'Bulk Upload',
    bulkUpload: 'FV',
    menuIcon: 'input',
    roles: FVEditRoles,
    color: 'primary',
  },
  {
    label: 'New FN',
    routerLink: '/fv',
    menuIcon: 'note_add',
    roles: FVEditRoles,
    color: 'primary',
  },
];

@Component({
  selector: 'app-grid-collection',
  templateUrl: './grid-collection.component.html',
  styleUrls: ['./grid-collection.component.scss'],
})
export class GridCollectionComponent implements OnInit {
  @ViewChild('trigger') trigger: MatMenuTrigger;
  @ViewChild('searchToolbar') searchToolbar: SearchToolbarComponent;
  @Input() pageableCollection: PageableCollection<ForeignVisitor>;
  @Input() loading: boolean = false;
  @Output() emitAdd = new EventEmitter<ForeignVisitor>();
  @Output() emitRemove = new EventEmitter<ForeignVisitor>();
  @Input() position: 'fixed' | 'sticky' = 'sticky';
  @Input() filterParams: FilterParams = new FilterParams({});
  @Output() filterParamsChange = new EventEmitter<FilterParams>();
  view: CollectionView = '!';
  userPreference: UserPreferenceBlob | undefined;
  headerActionButtons: ActionButton[] = actionButtons;
  selectDisplayed: boolean = false;
  ScreeningResult = ScreeningResult;
  farScreeningEligibility: boolean = false;
  filterFields = FvAdvancedSearch;
  groupSelectionMode: boolean = false;
  FvSorts = FvSorts;
  label: string = 'Foreign Nationals (FN)';

  constructor(
    private config: AppConfigService,
    private viewService: ViewService,
    private auth: AuthService,
    private userPreferenceService: UserPreferenceService,

    private dialog: MatDialog,
    private alert: AlertService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.farScreeningEligibility =
      this.config.get('farScreeningEligibility') || false;

    this.route.queryParams.subscribe((params) => {
      if (JSON.stringify(this.filterParams) !== JSON.stringify(params)) {
        let newParams = new FilterParams({ ...this.filterParams, ...params });
        this.onFilterParamChange(newParams);
      }
    });

    this.userPreferenceService.userPreferenceSubject$.subscribe(
      (hasUserPreference) => {
        if (hasUserPreference !== null && hasUserPreference !== undefined) {
          this.getUserPreference();
        } else {
          this.viewService.viewChangeSubject.subscribe((y) => {
            if (y.name === this.label) {
              this.view = y.viewType;
            }
          });
        }
      }
    );
  }

  getUserPreference() {
    this.userPreferenceService
      .getCurrentUserId(this.auth?.getUser()?.id!)
      .pipe(take(1), debounceTime(1000))
      .subscribe(
        (result) => {
          if (result !== null && result !== undefined) {
            this.userPreference = JSON.parse(result.userPreferenceBlob);
            let tabDetails = this.userPreference?.tabs.at(0);
            this.view = this.userPreference?.tabs.at(0)?.tabViewType!;
            let fnArray: Array<string> = [];
            tabDetails?.tabColumns.filter((x) => {
              if (x.selected === true) {
                fnArray.push(x.label);
              }
            });
            if (
              this.userPreference !== null &&
              this.userPreference !== undefined
            ) {
              this.userPreferenceService.loadDisplayColumns(
                FvSortFieldLabels,
                fnArray,
                FVTableColumns,
                FVTableName,
                this.auth?.getUser()?.roles!,
                this.auth.getUser()?.networkDomain!
              );
            }
          } else {
            this.view = 'card';
          }
        },
        (err) => {}
      );
  }

  onViewChange(view: CollectionView) {
    this.view = view;
  }

  onFilterParamChange(filterParams: FilterParams) {
    this.filterParamsChange.emit(filterParams);
  }

  hideFilter() {
    this.selectDisplayed = !this.selectDisplayed;
  }

  emitAddEvent(fv: ForeignVisitor) {
    this.emitAdd.emit(fv);
    this.trigger.closeMenu();
  }

  groupModeChange(change: boolean) {
    if (change !== null) this.groupSelectionMode = change;
    if (!this.groupSelectionMode) this.deselectAllFns();

    if (change === null) {
      if (this.isFnSelected()) {
        let dialogRef: MatDialogRef<FnGroupDialogComponent> =
          this.dialog.open<FnGroupDialogComponent>(FnGroupDialogComponent, {
            data: this.pageableCollection.content,
          });
        dialogRef.afterClosed().subscribe((result) => {
          this.groupSelectionMode = false;
          this.searchToolbar.groupSelectionMode = false;
          this.deselectAllFns();
        });
      } else {
        this.alert.infoAlert(
          'At least one Foreign National must be selected to create a group.'
        );
      }
    }
  }

  deselectAllFns() {
    for (let fn of this.pageableCollection.content) fn.selected = false;
  }

  isFnSelected() {
    for (let fn of this.pageableCollection.content)
      if (fn.selected) return true;
  }

  protected readonly FvSortFieldLabels = FvSortFieldLabels;
  protected readonly FVTableColumns = FVTableColumns;
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UserSortFieldLabels,
  OrgSortFieldLabels,
  FvSortFieldLabels,
  FarSortFieldLabels,
  OrgLocationSortLabels,
  AlertService,
} from '@app/shared/services';
import {
  ScreeningFieldLabels,
  fvColumns,
} from '@app/screening/collection/screening-table/screening-table.component';
import { AuthService } from '@app/auth/auth.service';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import {
  CheckBoxProperties,
  LoadedDisplay,
  Role,
  TabDetails,
  UserPreference,
  UserPreferenceBlob,
} from '@app/shared/models';
import {
  FVTableColumns,
  FVTableName,
} from '@app/foreign-visitors/dashboard/fv-table/fv-table.component';
import {
  FARTableColumns,
  FARTableName,
} from '@app/foreign-access-requests/dashboard/collections/far-table/far-table.component';
import {
  UserTableColumns,
  UserTableName,
} from '@app/admin/accounts/accounts-collection/account-user-table/account-user-table.component';
import {
  OrgTableColumns,
  OrgTableName,
} from '@app/organizations/collection/organization-collection-table/organization-collection-table.component';
import {
  LocationTableColumns,
  LocationTableName,
} from '@app/locations/collection/location-collection-table/location-collection-table.component';
import { ViewDetails, ViewService } from '@app/shared/services/view.service';
import {
  AdminViewRoles,
  FVViewRoles,
  FarViewRoles,
  LocViewRoles,
  ScreeningRoles,
} from '@app/shared/models/role-permissions';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

export const ViewNamesConstants = [
  'Home',
  'Foreign Nationals (FN)',
  'Foreign Access Request (FAR)',
  'Screening Review',
  'Locations',
  'Organizations',
  'User Administration',
];

import * as displayData from '../../assets/displayData.json';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

export const AdditionalTableColumns = ['Additional Data', 'Actions'];

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
})
export class UserPreferencesComponent implements OnInit {
  @ViewChild('tabGroup', { static: false }) tab: MatTabGroup;
  tabTitle: string = ViewNamesConstants.at(0)!;
  tabIndex: number = 0;
  viewDetails: ViewDetails | undefined;
  checkedColumns: string[] | undefined;
  userId: string;
  data: Array<LoadedDisplay> = displayData;
  displayCheckedColumns: Array<CheckBoxProperties> = [];
  isUpdated: boolean = false;
  displayViews: any[] = [];
  displayLabels: any;
  tableName: string;
  tableColumns: any;
  pageLink: string;
  preferenceView: string;
  currentUserId: string;
  savedUserPreference: UserPreferenceBlob | undefined;
  userPreference: UserPreference | undefined;
  userPreferenceBlob: UserPreferenceBlob | undefined;
  _fnViewType: string = 'card';
  _farViewType: string = 'card';
  _viewType: string = 'card';
  network: string = '';
  userRoles: Role[];
  ScreeningRoles = ScreeningRoles;
  LocViewRoles = LocViewRoles;
  AdminViewRoles = AdminViewRoles;
  tabDetails: Array<TabDetails> = [];
  isCancel = false;

  userPreferenceForm: FormGroup;
  newArray: FormArray;
  prefview = '';
  constructor(
    private authService: AuthService,
    private userPreferenceService: UserPreferenceService,
    private viewService: ViewService,
    private alert: AlertService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loadSavedPreference();
    this.userRoles = this.authService?.getUser()?.roles!;
    this.network = this.authService.getUser()?.networkDomain!;
    this.displayRolesViews(this.userRoles, ViewNamesConstants);
    this.userPreferenceForm = this.fb.group({
      prefviews: [this.displayViews],
      fv: this.fb.group({
        fnCheckboxes: this.populateCheckboxes(ViewNamesConstants.at(1)!),
        fnViewType: [this._fnViewType],
      }),
      far: this.fb.group({
        farCheckboxes: this.populateCheckboxes(ViewNamesConstants.at(2)!),
        farViewType: [this._farViewType],
      }),
      screen: this.fb.group({
        screenCheckboxes: this.populateCheckboxes(ViewNamesConstants.at(3)!),
      }),
      org: this.fb.group({
        orgCheckboxes: this.populateCheckboxes(ViewNamesConstants.at(5)!),
      }),
      location: this.fb.group({
        locationCheckboxes: this.populateCheckboxes(ViewNamesConstants.at(4)!),
      }),
      admin: this.fb.group({
        adminCheckboxes: this.populateCheckboxes(ViewNamesConstants.at(6)!),
      }),
    });
  }

  populateCheckboxes(name: string) {
    const load = Object.entries(this.data).filter(
      (value) => value[1].viewName === name
    );

    const loadValues = Object.values(load);
    if (this.userPreference != null) {
      this.preferenceView = this.getSavedUserPreferenceBlob().selectedViewName;
      Object.entries(this.getSavedUserPreferenceBlob().tabs).filter((value) => {
        if (value[1].tabName === name) {
          this.displayCheckedColumns.push(...value[1].tabColumns);
          this.setViewType(value[1].tabName, value[1].tabViewType!);
        }
      });
    } else {
      Object.values(loadValues).forEach((value) => {
        if (value[1].viewName != null) {
          let arrayResult: Array<CheckBoxProperties> = [];
          Object.values(value[1].checkboxes!).forEach((e) => {
            arrayResult.push({ selected: true, label: e.label });
          });

          this.displayCheckedColumns = arrayResult;
        }
      });
    }
    this.newArray = new FormArray(
      this.displayCheckedColumns.map((x) => {
        return new FormControl({ selected: x.selected, label: x.label });
      })
    );
    return this.newArray;
  }

  loadSavedTab(tabName: string, result: UserPreferenceBlob) {
    Object.entries(result.tabs).forEach((x, y) => {
      this.tabTitle = x[1].tabName;
      this.userPreferenceService.isUpdated(true);
      this.isUpdated = true;
      if (x[1].tabName != null) {
        this.userPreferenceService.sendUpdatedCheckboxes({
          viewName: x[1].tabName,
          checkboxes: x[1].tabColumns,
          viewType: x[1].tabViewType,
        });
      }
    });
    this.userPreferenceService.isUpdated(false);
    this.preferenceView = result.selectedViewName;
    this.setTab(result.selectedViewName);
  }

  setTab(tabName: string) {
    switch (tabName) {
      case 'Home':
      case 'Foreign Nationals (FN)':
        this.tabTitle = ViewNamesConstants.at(1)!;
        this.setTabIndex(0);
        break;
      case 'Foreign Access Request (FAR)':
        this.tabTitle = ViewNamesConstants.at(2)!;
        this.setTabIndex(1);
        break;
      case 'Screening Review':
        !this.authService.getUser()?.roles!.includes(Role.sv_vetter)
          ? this.setTabIndex(0)
          : this.setTabIndex(2);
        this.tabTitle = ViewNamesConstants.at(3)!;

        break;
      case 'Locations':
        this.authService.getUser()?.roles!.includes(Role.sv_vetter)
          ? this.setTabIndex(4)
          : this.setTabIndex(3);
        this.tabTitle = ViewNamesConstants.at(4)!;
        break;
      case 'Organizations':
        this.authService.getUser()?.roles!.includes(Role.sv_vetter)
          ? this.setTabIndex(3)
          : this.setTabIndex(2);
        this.tabTitle = ViewNamesConstants.at(5)!;
        break;
      case 'User Administration':
        this.authService.getUser()?.roles!.includes(Role.sv_vetter)
          ? this.setTabIndex(5)
          : this.setTabIndex(4);
        this.tabTitle = ViewNamesConstants.at(6)!;
        break;
    }
  }

  setViewType(tabName: string, viewType: string): string {
    if (tabName === (ViewNamesConstants.at(1) || ViewNamesConstants.at(0))) {
      this._fnViewType = this.userPreferenceForm
        .get('fv')
        ?.get('fnViewType')?.value;
      this._viewType = this._fnViewType;
    } else if (tabName === ViewNamesConstants.at(2)) {
      this._farViewType = this.userPreferenceForm
        .get('far')
        ?.get('farViewType')?.value;
      this._viewType = this._farViewType;
    } else {
      this._viewType = this._farViewType;
    }
    return this._viewType;
  }

  loadTabsFromJson() {
    let tabName: string = '';
    let arrayResult: Array<CheckBoxProperties> = [];
    Object.entries(this.data).forEach((key, value) => {
      if (key[1].viewName != null) {
        this.tabTitle = tabName;
        tabName = key[1].viewName!;
        this.displayCheckedColumns = key[1].checkboxes!;

        this.setViewType(tabName, key[1].viewType!);
        Object.entries(key[1].checkboxes!).forEach((e) => {
          arrayResult.push({ selected: true, label: e[1].label });
        });
      }
    });
    this.setTab(this.preferenceView);
  }

  setTabIndex($event: number) {
    this.tabIndex = $event;
  }

  onSubmit() {}

  displayRolesViews(userRoles: Role[], _systemViews: any): Array<String> {
    if (userRoles != null) {
      this.displayViews.push(_systemViews.at(0));
      FVViewRoles.filter((item) => {
        if (userRoles.includes(item)) {
          if (!this.displayViews.includes(_systemViews.at(1))) {
            this.displayViews.push(_systemViews.at(1));
          }
        }
      });
      FarViewRoles.filter((item) => {
        if (userRoles.includes(item)) {
          if (!this.displayViews.includes(_systemViews.at(2))) {
            this.displayViews.push(_systemViews.at(2));
          }
        }
      });
      ScreeningRoles.filter((item) => {
        if (userRoles.includes(item)) {
          if (!this.displayViews.includes(_systemViews.at(3))) {
            this.displayViews.push(_systemViews.at(3));
          }
        }
      });
      LocViewRoles.filter((item) => {
        if (userRoles.includes(item)) {
          if (!this.displayViews.includes(_systemViews.at(5))) {
            this.displayViews.push(_systemViews.at(5));
          }
          if (!this.displayViews.includes(_systemViews.at(4))) {
            this.displayViews.push(_systemViews.at(4));
          }
        }
      });
      AdminViewRoles.filter((item) => {
        if (userRoles.includes(item)) {
          if (!this.displayViews.includes(_systemViews.at(6))) {
            this.displayViews.push(_systemViews.at(6));
          }
        }
      });
    }
    return this.displayViews;
  }

  getSavedUserPreferenceBlob(): UserPreferenceBlob {
    return this.userPreferenceBlob!;
  }

  getSavedUserPreference(): UserPreference {
    return this.userPreference!;
  }

  setSavedUserPreference(savedPref: UserPreference): void {
    this.userPreference = savedPref;
  }

  setSavedUserPreferenceBlob(savedPrefBlob: UserPreferenceBlob): void {
    this.userPreferenceBlob = savedPrefBlob;
  }

  loadSavedPreference() {
    this.currentUserId = this.authService?.getUser()?.id!;
    this.userPreferenceService.getCurrentUserId(this.currentUserId).subscribe(
      (result) => {
        if (result != null) {
          this.setSavedUserPreference(result!);
          this.userPreference = result;
          let value = result.userPreferenceBlob.toString;
          let saveduserPreferenceBlob: UserPreferenceBlob = JSON.parse(
            result.userPreferenceBlob
          );
          this.preferenceView = saveduserPreferenceBlob.selectedViewName;
          this.setSavedUserPreferenceBlob(saveduserPreferenceBlob!);
          this.savedUserPreference = saveduserPreferenceBlob;
          this.setViewType(
            saveduserPreferenceBlob.selectedViewName,
            saveduserPreferenceBlob.selectedViewType!
          );
          this.checkedColumns = saveduserPreferenceBlob.selectedViewColumns;
          this.loadSavedTab(
            saveduserPreferenceBlob.selectedViewName,
            saveduserPreferenceBlob
          );
        } else {
          this.loadTabsFromJson();
        }
      },
      (err) => this.alert.errorAlert('Unable to load user preference ')
    );
    return this.savedUserPreference;
  }

  selectedView(view: any) {
    this.preferenceView = view;
    switch (this.preferenceView) {
      case 'Home':
        this.pageLink = '/';
        break;
      case 'Foreign Nationals (FN)':
        this.displayLabels = FvSortFieldLabels;
        this.tableColumns = FVTableColumns;
        this.tableName = FVTableName;
        this.pageLink = '/fvs';
        break;
      case 'Foreign Access Request (FAR)':
        this.displayLabels = FarSortFieldLabels;
        this.tableColumns = FARTableColumns;
        this.tableName = FARTableName;
        this.pageLink = '/fars';
        break;
      case 'Screening Review':
        this.displayLabels = ScreeningFieldLabels;
        this.tableColumns = fvColumns;

        this.tableName = '';
        this.pageLink = '/screening';
        break;
      case 'Locations':
        this.displayLabels = OrgLocationSortLabels;
        this.tableColumns = LocationTableColumns;
        this.tableName = LocationTableName;
        this.pageLink = '/locations';
        break;
      case 'Organizations':
        this.displayLabels = OrgSortFieldLabels;
        this.tableColumns = OrgTableColumns;
        this.tableName = OrgTableName;
        this.pageLink = '/organizations';
        break;
      case 'User Administration':
        this.displayLabels = UserSortFieldLabels;
        this.tableColumns = UserTableColumns;
        this.tableName = UserTableName;
        this.pageLink = '/admin';
        break;
      default:
        this.pageLink = '/';
        break;
    }
  }

  setCheckedColumns(columns: Array<string>): void {
    this.checkedColumns = columns;
  }

  saveChanges() {
    this.displayCheckedColumns = [];
    let fnValues = this.userPreferenceForm
      .get('fv')
      ?.get('fnCheckboxes') as FormArray;
    fnValues.value.forEach((l: CheckBoxProperties) => {
      this.displayCheckedColumns.push({ selected: l.selected, label: l.label });
    });
    this.tabDetails.push({
      tabName: 'Foreign Nationals (FN)',
      tabColumns: this.displayCheckedColumns,
      tabViewType: this.userPreferenceForm.get('fv')?.get('fnViewType')?.value,
    });

    this.displayCheckedColumns = [];
    let farValues = this.userPreferenceForm
      .get('far')
      ?.get('farCheckboxes') as FormArray;

    farValues.value.forEach((l: CheckBoxProperties) => {
      this.displayCheckedColumns.push({ selected: l.selected, label: l.label });
    });
    this.tabDetails.push({
      tabName: 'Foreign Access Request (FAR)',
      tabColumns: this.displayCheckedColumns,
      tabViewType: this.userPreferenceForm.get('far')?.get('farViewType')
        ?.value,
    });

    this.displayCheckedColumns = [];
    let screenValues = this.userPreferenceForm
      .get('screen')
      ?.get('screenCheckboxes') as FormArray;

    screenValues.value.forEach((l: CheckBoxProperties) => {
      this.displayCheckedColumns.push({ selected: l.selected, label: l.label });
    });
    this.tabDetails.push({
      tabName: 'Screening Review',
      tabColumns: this.displayCheckedColumns,
    });

    this.displayCheckedColumns = [];
    let orgValues = this.userPreferenceForm
      .get('org')
      ?.get('orgCheckboxes') as FormArray;
    orgValues.value.forEach((l: CheckBoxProperties) => {
      this.displayCheckedColumns.push({ selected: l.selected, label: l.label });
    });
    this.tabDetails.push({
      tabName: 'Organizations',
      tabColumns: this.displayCheckedColumns,
    });

    this.displayCheckedColumns = [];
    let locValues = this.userPreferenceForm
      .get('location')
      ?.get('locationCheckboxes') as FormArray;

    locValues.value.forEach((l: CheckBoxProperties) => {
      this.displayCheckedColumns.push({ selected: l.selected, label: l.label });
    });
    this.tabDetails.push({
      tabName: 'Locations',
      tabColumns: this.displayCheckedColumns,
    });

    this.displayCheckedColumns = [];
    let adminValues = this.userPreferenceForm
      .get('admin')
      ?.get('adminCheckboxes') as FormArray;

    adminValues.value.forEach((l: CheckBoxProperties) => {
      this.displayCheckedColumns.push({ selected: l.selected, label: l.label });
    });
    this.tabDetails.push({
      tabName: 'User Administration',
      tabColumns: this.displayCheckedColumns,
    });

    this.setViewType(this.preferenceView, this._viewType!);
    if (
      this.preferenceView === ViewNamesConstants.at(1) ||
      this.preferenceView === ViewNamesConstants.at(0)
    ) {
      this._fnViewType = this.userPreferenceForm
        .get('fv')
        ?.get('fnViewType')?.value;
      this._viewType = this._fnViewType;
    } else if (this.preferenceView === ViewNamesConstants.at(2)) {
      this._farViewType = this.userPreferenceForm
        .get('far')
        ?.get('farViewType')?.value;
      this._viewType = this._farViewType;
    }

    if (
      this.preferenceView === 'Home' ||
      this.preferenceView === '' ||
      this.preferenceView === 'Select View' ||
      this.preferenceView == null
    ) {
      this.preferenceView = 'Home';
      this.checkedColumns = [];
      this._viewType = this._fnViewType;
    } else {
      this.checkedColumns = this.getViewSelectedColumns(
        this.preferenceView,
        this.tabDetails
      );
    }

    if (this.userPreference != null) {
      this.userPreferenceBlob = {
        userId: this.currentUserId,
        selectedViewName: this.preferenceView,
        selectedViewColumns: this.getViewSelectedColumns(
          this.preferenceView,
          this.tabDetails
        ),
        selectedViewType: this._viewType,
        tabs: this.tabDetails,
      };

      let jsonString = JSON.stringify(this.userPreferenceBlob);
      this.userPreferenceService
        .updatePreference(this.currentUserId, {
          userPreferenceBlob: jsonString,
          userId: this.currentUserId,
        })
        .subscribe((result) => {
          let updateBlob: UserPreferenceBlob = JSON.parse(
            result.userPreferenceBlob
          );
          this.loadUserPreference(updateBlob);
          this.alert.successAlert('UserPreference updated successfully');
        });
    } else {
      this.userPreferenceBlob = {
        userId: this.currentUserId,
        selectedViewName: this.preferenceView,
        selectedViewColumns: this.getViewSelectedColumns(
          this.preferenceView,
          this.tabDetails
        ),
        selectedViewType: this._viewType,
        tabs: this.tabDetails,
      };
      const jsonString = JSON.stringify(this.userPreferenceBlob);
      this.userPreferenceService
        .create({
          userPreferenceBlob: jsonString,
          userId: this.currentUserId,
        })
        .subscribe(
          (result) => {
            this.userPreferenceBlob = JSON.parse(result.userPreferenceBlob);
            this.loadUserPreference(this.userPreferenceBlob!);
          },
          (err) => (this.isUpdated = false)
        );
    }
  }

  getViewSelectedColumns(
    viewName: string,
    tabInfo: TabDetails[]
  ): Array<string> {
    let checkedColumns: Array<string> = [];
    const tabDetails = Object.entries(tabInfo).forEach((x) => {
      if (x[1].tabName === viewName) {
        x[1].tabColumns.forEach((x2) => {
          if (x2.selected === true) {
            checkedColumns.push(x2.label);
          }
        });
      }
    });

    return checkedColumns;
  }

  cancel() {
    if (this.savedUserPreference == null) {
      this.authService.navigateToUserPreference('/');
    } else {
      this.userPreferenceService
        .getCurrentUserId(this.currentUserId)
        .subscribe((result) => {
          if (result !== null && result !== undefined) {
            let saveduserPreferenceBlob: UserPreferenceBlob = JSON.parse(
              result.userPreferenceBlob
            );
            this.loadUserPreference(saveduserPreferenceBlob);
          }
        });
    }
  }

  loadUserPreference(result: UserPreferenceBlob) {
    this.selectedView(result.selectedViewName);
    if (result.selectedViewName === 'Home' || result.selectedViewName === '') {
      this.authService.navigateToUserPreference(this.pageLink);
    } else if (this.displayViews.includes(result.selectedViewName)) {
      this.selectedView(result.selectedViewName);
      if (result.selectedViewType) {
        if (
          result.selectedViewName === ViewNamesConstants.at(1) ||
          result.selectedViewName === ViewNamesConstants.at(2)
        ) {
          this.userPreferenceService.hasUserPreference(
            this.authService.getUser()?.id!,
            result.selectedViewName
          );
          this.preferenceView = result.selectedViewName;
          this._viewType = this.setViewType(
            this.preferenceView,
            result.selectedViewType!
          );
        }
      }

      if (
        result.selectedViewColumns != null &&
        result.selectedViewColumns !== undefined
      ) {
        this.userPreferenceService.loadDisplayColumns(
          this.displayLabels,
          result.selectedViewColumns!,
          this.tableColumns,
          this.tableName!,
          this.userRoles,
          this.network!
        );
      }
      this.authService.navigateToUserPreference(this.pageLink);
    }
  }

  setPlaceholder(value: string) {
    this.preferenceView === ''
      ? this.savedUserPreference?.selectedViewName!
      : 'Select View';
  }

  onTabChange($event: MatTabChangeEvent) {
    this.tabTitle = $event.tab.textLabel;
  }
}

import { Component, Input } from '@angular/core';
import {
  Address,
  ForeignVisitor,
  ContactEmail,
  Passport,
  Visa,
  ContactPhoneNumber,
} from '@app/shared/models';

@Component({
  selector: 'app-fv-summary',
  templateUrl: './fv-summary.component.html',
  styleUrls: ['./fv-summary.component.scss'],
})
export class FvSummaryComponent {
  @Input() reviewFV?: ForeignVisitor;
  @Input() fvEmail: ContactEmail;
  @Input() fvPhone: ContactPhoneNumber;
  @Input() passport?: Passport = {};
  @Input() visa?: Visa = {};
  @Input() address?: Address = {};
  @Input() formInvalid = true;
}

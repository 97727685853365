<div class="component-container">
  <h5>
    Biographic Information
    <mat-icon
      style="margin-bottom: -5px"
      matTooltip="For Foreign Nationals with a Single Name, enter the single name into the Surname field and 'FNU' into the Given Name field"
      >help</mat-icon
    >
  </h5>
  <form #bioForm="ngForm" id="bioForm">
    <div class="row justify-content-between">
      <div class="col-4">
        <mat-form-field [appearance]="appearance">
          <mat-label matTooltip="First Name">Given Name</mat-label>
          <input
            id="givenName"
            matInput
            required
            #givenNameControl="ngModel"
            [(ngModel)]="value.givenName"
            (ngModelChange)="emitChangeEvent()"
            placeholder="Enter Given name (First Name)"
            name="givenName"
            maxlength="256"
            aria-label="first name"
          />
          <mat-error>Please add a valid Given Name</mat-error>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field [appearance]="appearance">
          <mat-label matTooltip="Last Name">Surname</mat-label>
          <input
            id="surname"
            matInput
            required
            #surnameControl="ngModel"
            [(ngModel)]="value.surname"
            (ngModelChange)="emitChangeEvent()"
            name="surname"
            maxlength="256"
            placeholder="Enter Surname (Last Name)"
            aria-label="last name"
          />
          <!-- ERROR -->
          <mat-error> Please add a valid Surname</mat-error>
        </mat-form-field>
      </div>
      <div class="col-4 d-flex align-items-center mb-4">
        <mat-slide-toggle
          id="vip-toggle"
          #vipControl="ngModel"
          name="vip"
          (ngModelChange)="emitChangeEvent()"
          [(ngModel)]="value.vip"
          >Expedite</mat-slide-toggle
        >
      </div>
      <div class="col-4">
        <mat-form-field [appearance]="appearance">
          <mat-label>Gender</mat-label>
          <mat-select
            id="fvgender"
            #genderControl="ngModel"
            [(ngModel)]="value.gender"
            (ngModelChange)="emitChangeEvent()"
            name="gender"
            aria-label="Please select an option"
            color="primary"
          >
            <mat-option selected disabled>Select a Gender</mat-option>
            <mat-option
              *ngFor="let option of gender"
              [value]="option | lowercase"
            >
              {{ option | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-form-field [appearance]="appearance">
          <mat-label>Date Of Birth</mat-label>
          <input
            id="dateOfBirth"
            matInput
            #dateOfBirthControl="ngModel"
            [matDatepicker]="dobPicker"
            [(ngModel)]="value.dateOfBirth"
            (ngModelChange)="emitChangeEvent()"
            name="dateOfBirth"
            [max]="today"
            placeholder="MM/DD/YYYY"
            aria-label="date of birth"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dobPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #dobPicker></mat-datepicker>
          <mat-error> Please add a valid date</mat-error>
        </mat-form-field>
      </div>
      <div class="col-4">
        <app-country-select
          id="birthCountry"
          [required]="false"
          #birthCountryCtl="ngModel"
          [(ngModel)]="value.birthCountry"
          (ngModelChange)="emitChangeEvent()"
          name="birthCountry"
          [placeholder]="'Country of Birth'"
          floatLabel="auto"
          label="Country of Birth"
        >
        </app-country-select>
      </div>
      <div class="col-4">
        <app-country-select
          id="citizenshipCountry"
          [required]="false"
          #citizenshipCountryControl="ngModel"
          [(ngModel)]="value.citizenshipCountry"
          (ngModelChange)="emitChangeEvent()"
          name="citizenshipCountry"
          [placeholder]="'Country of Citizenship'"
          [excludeUS]="true"
          floatLabel="auto"
          label="Country of Citizenship"
        >
        </app-country-select>
      </div>
      <div class="col-4">
        <app-country-select
          id="residenceCountry"
          [required]="false"
          #residenceCountryControl="ngModel"
          [(ngModel)]="value.residenceCountry"
          (ngModelChange)="emitChangeEvent()"
          name="residenceCountry"
          [placeholder]="'Country of Residence'"
          floatLabel="auto"
          label="Country of Residence"
        >
        </app-country-select>
      </div>
      <span class="col"></span>
    </div>
  </form>
</div>

import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  CheckBoxProperties,
  Role,
  UserPreferenceBlob,
} from '@app/shared/models';
import { OrgLocationSortLabels } from '@app/shared/services';
import { AdditionalTableColumns } from '../user-preferences.component';
import {
  LocationTableColumns,
  LocationTableName,
} from '@app/locations/collection/location-collection-table/location-collection-table.component';
import { AuthService } from '@app/auth/auth.service';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import {
  ControlContainer,
  FormArray,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
@Component({
  selector: 'app-location-tab-checkbox',
  templateUrl: './location-tab-checkbox.component.html',
  styleUrls: ['./location-tab-checkbox.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class LocationTabCheckboxComponent implements OnInit, OnChanges {
  @Input() updated: boolean;
  @Input() checkedColumns: Array<string> = [];
  @Input() userRoles: Role[];
  @Input() tabTitle: string;
  @Input() previewName: string;
  tabLabel: string = 'Locations';
  tabIndex: number;
  checkedColumnss: Array<string>;
  locCheckedColumns: Array<CheckBoxProperties> = [];
  locColumnNames: Array<string> = [];
  displayCheckedColumns: CheckBoxProperties[];
  _columnNames: Array<string> = [];
  locationForm!: FormGroup;

  constructor(
    private authService: AuthService,
    private userPreferenceService: UserPreferenceService,
    private rootFormGroup: FormGroupDirective
  ) {}

  ngOnInit(): void {
    this.loadCheckboxes();
    this.locationForm = this.rootFormGroup.control.get('location') as FormGroup;
  }

  loadCheckboxes() {
    this.tabIndex = 4;
    if (this.locCheckedColumns.length === 0) {
      Object.values(OrgLocationSortLabels).forEach((value) => {
        if (value !== 'City' && value !== 'State/Province') {
          this.locColumnNames.push(value);
          this.locCheckedColumns.push({ selected: true, label: value });
        }
      });
      if (!this.locColumnNames.includes(AdditionalTableColumns.at(1)!)) {
        this.locColumnNames.push(AdditionalTableColumns[1]);
        this.locCheckedColumns.push({
          selected: true,
          label: AdditionalTableColumns[1],
        });
      }
      this._columnNames = this.locColumnNames;
    }
    this.displayCheckedColumns = this.locCheckedColumns;
    this._columnNames = this.locColumnNames;
    this.displayCheckedColumns = this.setDisplayCheckedColumns(
      this.locCheckedColumns,
      this.setCheckedColumns(this.locCheckedColumns)
    );
  }

  selectedColumns(selectedOption: MatCheckboxChange) {
    this.displayCheckedColumns.forEach((c) => {
      if (selectedOption.source.name === c.label) {
        selectedOption.checked ? (c.selected = true) : (c.selected = false);
      }
    });
    this.locationCheckboxes.controls.forEach((value, index) => {
      const newValue = value.value;
      if (newValue.label === selectedOption.source.name) {
        if (selectedOption.checked) {
          value.patchValue({ selected: true, label: newValue.label });
        } else {
          value.patchValue({ selected: false, label: newValue.label });
        }
      }
    });

    this.checkedColumnss = this.setCheckedColumns(this.displayCheckedColumns);
  }

  toggleAll(toggle: boolean) {
    this.locCheckedColumns = [];
    this.checkedColumnss = [];
    if (toggle) {
      this.locationCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.locCheckedColumns.push({ selected: true, label: newValue.label });
        value.patchValue({ selected: true, label: newValue.label });
        this.checkedColumnss.push(newValue.label);
      });
    } else {
      this.locationCheckboxes.controls.forEach((value, index) => {
        const newValue = value.value;
        this.locCheckedColumns.push({ selected: false, label: newValue.label });
        value.patchValue({ selected: false, label: newValue.label });
      });

      this.checkedColumnss = [];
    }
    this.displayCheckedColumns = this.locCheckedColumns;
  }

  get locationCheckboxes(): FormArray {
    return this.locationForm.get('locationCheckboxes') as FormArray;
  }

  setDisplayCheckedColumns(
    checkedArray: Array<CheckBoxProperties>,
    checkedArrayColumns: string[]
  ): Array<CheckBoxProperties> {
    let trueCheckedColumns: Array<CheckBoxProperties> = [];
    checkedArray.forEach((c) => {
      checkedArrayColumns.includes(c.label)
        ? trueCheckedColumns.push({
            selected: true,
            label: c.label,
          })
        : trueCheckedColumns.push({
            selected: false,
            label: c.label,
          });
    });

    return trueCheckedColumns;
  }

  setCheckedColumns(checkedArray: Array<CheckBoxProperties>): string[] {
    let trueCheckedColumns: string[] = [];
    checkedArray.forEach((c) => {
      if (c.selected) {
        trueCheckedColumns.push(c.label);
      }
    });
    return trueCheckedColumns;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userPreferenceService.updated.subscribe((result) => {
      if (result) {
        this.userPreferenceService.updateCheckboxes.subscribe((checkit) => {
          if (this.tabLabel === checkit.viewName) {
            this.checkedColumnss = this.setCheckedColumns(checkit.checkboxes!);
            this.locCheckedColumns = checkit.checkboxes!;
            this.checkedColumnss = this.setCheckedColumns(
              this.locCheckedColumns
            );
            this.displayCheckedColumns = this.setDisplayCheckedColumns(
              this.locCheckedColumns,
              this.checkedColumnss
            );
            this.locationCheckboxes.controls.forEach((value, index) => {
              const newValue = value.value;
              checkit.checkboxes?.filter((x) => {
                if (x.label === newValue.label) {
                  value.patchValue({ selected: x.selected, label: x.label });
                }
              });
            });
          }
        });
      }
    });
    if (changes['tabTitle']) {
      if (changes['tabTitle'].currentValue !== null) {
        this.tabTitle = changes['tabTitle'].currentValue;
      }
    }
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FilterParams } from '@shared/models';
import {
  BaseOptionFilter,
  clearField,
  FilterConfig,
  FilterConfigType,
  FNFilterConfig,
  GroupIDFilterConfig,
  isFilterActive,
  LocationIDFilterConfig,
  OrgFilterConfig,
  resolveOpt,
  resolveProp,
  UserFilterConfig,
} from '@shared/components/advanced-search/filter-config';
import { isArray, isNil, without } from 'lodash';
import {
  FvService,
  GroupService,
  OrganizationService,
  OrgLocationService,
  UserService,
} from '@shared/services';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-active-search-display',
  templateUrl: './active-search-display.component.html',
  styleUrls: ['./active-search-display.component.scss'],
})
export class ActiveSearchDisplayComponent implements OnInit, OnChanges {
  // Just saving filter ref objs, in case needed from special filters (FN, Org, etc)
  filterRefObjs: { [key: string]: any } = {};

  @Input() filterParams: FilterParams;
  @Input() filterFields: FilterConfig[];

  @Output() filterParamsChange = new EventEmitter<FilterParams>();

  constructor(
    private fvService: FvService,
    private orgService: OrganizationService,
    private userService: UserService,
    private orgLocationService: OrgLocationService,
    private groupService: GroupService
  ) {}

  ngOnInit() {
    this.refreshRefObjs();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshRefObjs();
  }

  refreshRefObjs() {
    this.filterFields?.forEach((filter) => {
      if (!this.filterParams[filter.name]) {
        delete this.filterRefObjs[filter.name];
        return;
      }

      if (
        this.filterRefObjs[filter.name]?.id === this.filterParams[filter.name]
      )
        return;

      delete this.filterRefObjs[filter.name];

      if (filter.type === FilterConfigType.ORG) this.loadOrg(filter);
      if (filter.type === FilterConfigType.USER) this.loadUser(filter);
      if (filter.type === FilterConfigType.FN) this.loadFN(filter);
      if (filter.type === FilterConfigType.LOCATION_ID)
        this.loadLocation(filter);
      if (filter.type === FilterConfigType.GROUP_ID) this.loadGroup(filter);
    });
  }

  loadOrg(filter: OrgFilterConfig) {
    this.orgService
      .get(this.filterParams[filter.name])
      .pipe(take(1))
      .subscribe((data) => (this.filterRefObjs[filter.name] = data));
  }

  loadUser(filter: UserFilterConfig) {
    this.userService
      .get(this.filterParams[filter.name])
      .pipe(take(1))
      .subscribe((data) => (this.filterRefObjs[filter.name] = data));
  }

  loadFN(filter: FNFilterConfig) {
    this.fvService
      .get(this.filterParams[filter.name])
      .pipe(take(1))
      .subscribe((data) => (this.filterRefObjs[filter.name] = data));
  }

  loadLocation(filter: LocationIDFilterConfig) {
    this.orgLocationService
      .get(this.filterParams[filter.name])
      .pipe(take(1))
      .subscribe((data) => (this.filterRefObjs[filter.name] = data.name));
  }

  loadGroup(filter: GroupIDFilterConfig) {
    this.groupService
      .get(this.filterParams[filter.name])
      .pipe(take(1))
      .subscribe((data) => (this.filterRefObjs[filter.name] = data.name));
  }

  isActive(): boolean {
    return !!this.filterFields?.find((filter) => {
      return isFilterActive(filter, this.filterParams);
    });
  }

  clearAll() {
    this.filterFields?.forEach((config) => {
      clearField(config, this.filterParams);
      delete this.filterRefObjs[config.name];
    });
    this.refresh();
  }

  clear(config: FilterConfig, value: any) {
    if (isArray(this.filterParams[config.name])) {
      this.filterParams[config.name] = without(
        this.filterParams[config.name],
        value
      );
    } else {
      clearField(config, this.filterParams);
    }
    delete this.filterRefObjs[config.name];
    this.refresh();
  }

  refresh() {
    this.filterParamsChange.emit(this.filterParams);
  }

  splitFilterValues(filter: FilterConfig) {
    const value = this.filterParams[filter.name];
    return isArray(value) ? value : [value];
  }

  resolveOptValLabel(value: any, filter: BaseOptionFilter) {
    const values = isArray(value) ? value : isNil(value) ? [] : [value];

    return values.map((val) => {
      return resolveProp(
        resolveOpt(filter.options, val, filter.optVal),
        filter.optLabel
      );
    });
  }

  protected readonly FilterConfigType = FilterConfigType;
  protected readonly isFilterActive = isFilterActive;
}

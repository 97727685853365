import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import { FilterParams, Group } from '@app/shared/models';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { CrudService, GroupService, Repository } from '@app/shared/services';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-groups',
  templateUrl: 'groups.component.html',
  styleUrls: ['groups.component.scss'],
  providers: [Repository, { provide: CrudService, useClass: GroupService }],
})
export class GroupsComponent implements OnInit, AfterViewChecked {
  loading: boolean = false;
  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    sortBy: 'name',
    direction: 'ASC',
  });
  groups$: Observable<PageableCollection<Group>> = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );

  constructor(
    private repo: Repository<Group>,
    private changeDetect: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);
  }

  ngAfterViewChecked(): void {
    this.changeDetect.detectChanges();
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}

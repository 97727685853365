import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '@app/shared/services';
import { AppConfigService } from '@shared/services/app-config.services';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CitadelOauthService extends AuthService {
  constructor(
    protected config: AppConfigService,
    protected userService: UserService,
    protected router: Router,
    private oauthService: OAuthService
  ) {
    super(config, userService, router);

    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn(
        'Noticed changes to access_token (most likely from another tab), updating isAuthenticated'
      );
      this.authenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.subscribe((_) => {
      this.authenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });

    this.oauthService.events
      .pipe(filter((e) => ['token_received'].includes(e.type)))
      .subscribe((_e) => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(
        filter((e) => ['session_terminated', 'session_error'].includes(e.type))
      )
      .subscribe((_e) => this.navigateToLoginPage());

    this.oauthService.setupAutomaticSilentRefresh();
  }

  async init(): Promise<void> {
    // 0. LOAD CONFIG:
    await this.oauthService.loadDiscoveryDocument();
    // 1. HASH LOGIN:
    await this.oauthService.tryLogin();

    if (this.oauthService.hasValidAccessToken()) {
      await this._loadUser();
      if (this._hasStoreRedirect()) {
        this.redirectFromLogin();
      }
    }
  }

  async getAuthSummary(refresh?: boolean): Promise<null> {
    return null;
  }

  async showLoginInfo(): Promise<void> {}

  public async getAccessToken(): Promise<string> {
    return this.oauthService.getAccessToken();
  }

  public async getIdToken(): Promise<string> {
    return this.oauthService.getIdToken();
  }

  public async getClaim(key: string): Promise<any> {
    return get(this.oauthService.getIdentityClaims(), key);
  }

  private navigateToLoginPage(): Promise<boolean> {
    return this.router.navigateByUrl('/login');
  }

  async login(targetUrl = '/') {
    this._storeLoginRedirect(targetUrl);
    this.oauthService.initLoginFlow();
  }

  protected async _clearUser() {
    // Prevent error if currently loading user profile during logoff
    this.oauthService.skipSubjectCheck = true;
    this.oauthService.logOut();
    super._clearUser();
  }

  async logout() {
    this._clearUser();
    await this.navigateToLoginPage();
  }
}

<mat-card
  class="border-bottom fv-view-toolbar mb-1 header"
  data-cy="fv-toolbar"
>
  <div class="row w-100">
    <div class="col-4 align-items-center d-flex">
      <div class="ml-2 mt-1">
        <h5
          class="name-summary w-100 ml-2 row ff-nowrap"
          [class]="fv.isEditable ? 'mb-0' : 'mb-1'"
        >
          <div matTooltip="{{ fv | fullname: 'surnameFirst' | titlecase }}">
            {{ fv | fullname: "surnameFirst" | titlecase | shorten: 40:"..." }}
          </div>
          <button
            data-cy="edit-fv-bio-button"
            *ngIf="fv.isEditable"
            matTooltip="Edit Biographic Information"
            class="py-0 no-hover"
            style="height: 50%"
            mat-icon-button
            (click)="onEdit()"
            id="edit-bio-button"
            color="primary"
          >
            <mat-icon svgIcon="square-edit-outline"></mat-icon>
          </button>
          <mat-icon
            class="ml-2"
            svgIcon="pencil-off-outline"
            matTooltip="Read Only"
            color="primary"
            *ngIf="!fv.isEditable"
            style="opacity: 0.7"
          ></mat-icon>
        </h5>
        <div class="row ml-1 ff-nowrap">
          <app-avatar
            [picEditable]="fv.isEditable ? true : false"
            [fv]="fv"
            [size]="'medium'"
          ></app-avatar>
          <dl class="no-list-wrap ml-1">
            <dt>Age</dt>
            <dd>
              {{ fv.dateOfBirth! | age | default }}
            </dd>
            <dt>Gender</dt>
            <dd>
              {{ fv.gender | titlecase | default }}
            </dd>
          </dl>
          <dl class="no-list-wrap ml-3">
            <dt>Date of Birth</dt>
            <dd class="d-flex align-items-center">
              <mat-icon
                *ngIf="!fv.dateOfBirth"
                class="birth-warning mr-1"
                matTooltip="Birth Date Required For Screening"
                >warning</mat-icon
              >
              {{ (fv.dateOfBirth | militaryDate)?.toUpperCase() | default }}
            </dd>
            <dt>Birth Country</dt>
            <dd
              [matTooltip]="
                fv.birthCountry ? (fv.birthCountry | country | default) : ''
              "
            >
              {{ fv.birthCountry | country: "alpha3" | default }}
            </dd>
          </dl>
          <dl class="no-list-wrap ml-3">
            <dt>Country of Residence</dt>
            <dd
              [matTooltip]="
                fv.residenceCountry
                  ? (fv.residenceCountry | country | default)
                  : ''
              "
            >
              {{ fv.residenceCountry | country: "alpha3" | default }}
            </dd>
            <dt>Citizenship Country</dt>
            <dd
              [matTooltip]="
                fv.citizenshipCountry
                  ? (fv.citizenshipCountry | country | default)
                  : ''
              "
            >
              {{ fv.citizenshipCountry | country: "alpha3" | default }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="col-4 pr-0 justify-content-center align-items-center">
      <app-classification-banner
        class="d-block mt-2"
        [classificationModel]="fv"
        data-cy="classification-banner"
      ></app-classification-banner>
      <screening-insight-card
        class="d-flex align-items-center justify-content-center mb-1 mt-2"
        *ngIf="fv.latestScreening"
        [matTooltip]="
          'Screening State: ' + (fv.latestScreening | screeningLabel | default)
        "
        [value]="fv.latestScreening"
        [screeningUpdate]="fv"
        data-cy="screening-status-insight"
      ></screening-insight-card>
    </div>
    <div class="col-3"></div>
    <div
      class="col-1 align-items-end pr-0 d-flex justify-content-evenly"
      style="flex-direction: column"
      data-cy="actions"
    >
      <button
        style="margin-right: -8px; margin-top: -8px"
        id="fn-view-action-menu"
        data-cy="fn-action-menu"
        mat-icon-button
        [matMenuTriggerFor]="actionMenu"
        matTooltip="Actions Menu"
        matTooltipPosition="before"
      >
        <mat-icon svgIcon="dots-vertical"></mat-icon>
      </button>
      <app-resource-user-details
        [data]="fv"
        [fvVersion]="true"
      ></app-resource-user-details>
      <div *ngIf="fv.deletionDate">
        <mat-icon
          svgIcon="delete-clock-outline"
          [matBadge]="fv.deletionDate! | daysUntil | default"
          matBadgeColor="accent"
          matBadgeSize="small"
          matTooltip="Days until Delete: {{
            fv.deletionDate! | daysUntil | default
          }}"
          aria-hidden="false"
          matTooltipPosition="before"
          class="icon-size-30"
          color="primary"
        ></mat-icon>
      </div>
      <mat-icon
        class="icon-size-30"
        [svgIcon]="'alpha-' + fv.networkDomain?.toLowerCase() + '-box'"
        matTooltip="Network"
        matTooltipPosition="before"
        aria-hidden="false"
        color="primary"
      ></mat-icon>
      <mat-menu #actionMenu>
        <button
          id="new-fn-view"
          mat-menu-item
          [routerLink]="'/fv'"
          *appIfRoles="FVEditRoles"
        >
          <mat-icon>note_add</mat-icon>
          <span class="button-group-text">New FN</span>
        </button>
        <ng-container *ngIf="fv.isEditable">
          <!-- CLASSIFY BUTTON -->
          <button
            mat-menu-item
            id="fn-view-classify-btn"
            appClassify
            *appClassifyShow
            [classificationModel]="fv"
            (classificationModelChange)="onClassify($event)"
          >
            <mat-icon svgIcon="shield-lock"></mat-icon>
            <span class="button-group-text">Classify</span>
          </button>
        </ng-container>
        <button
          mat-menu-item
          color="primary"
          id="view-screening-fv-fvview"
          [matMenuTriggerFor]="screeningMenu"
          data-cy="screening-button"
        >
          <mat-icon>fingerprint</mat-icon>
          <span>Screening</span>
        </button>
        <ng-container *ngIf="fv.isDeletable">
          <span
            matTooltip="Delete Foreign National - Ensure Corresponding FN on Low Side Deleted First"
          >
            <button
              id="delete-fv-fvview"
              *appIfRoles="Role.sv_admin"
              mat-menu-item
              [appDeleteFv]="fv"
              [fvDeletedRoute]="['/fvs']"
              data-cy="delete-fv"
            >
              <mat-icon color="warn">delete</mat-icon>
              <span class="text-danger">Delete</span>
            </button>
          </span>
        </ng-container>
        <!--Change Editablity-->
        <ng-container *ngIf="false">
          <button
            id="change-fv-editability"
            *appIfRoles="Role.sv_admin"
            mat-menu-item
            (click)="fv.isEditable = !fv.isEditable"
          >
            <mat-icon>edit</mat-icon>
            <span>Change Editable</span>
          </button>
        </ng-container>
      </mat-menu>
      <mat-menu #screeningMenu>
        <button
          *appIfRoles="FVRescreenRoles"
          [disabled]="!fv.isRescreenable"
          mat-menu-item
          (click)="openConfirmationDialog(fv)"
          id="new-screening-button"
          data-cy="new-screening-button"
        >
          <mat-icon svgIcon="send-circle-outline"></mat-icon>
          Rescreen
        </button>
        <button
          mat-menu-item
          [screeningHistory]="fv"
          id="open-screening-history-button"
          data-cy="screening-history-button"
        >
          <mat-icon svgIcon="clipboard-text-clock"></mat-icon>
          Screening History
        </button>
      </mat-menu>
    </div>
  </div>
</mat-card>

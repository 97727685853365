<ng-container *ngIf="fv$ | async as fv">
  <div *ngIf="fars$ | async as fars" [class.linked-fv]="!!this.linkedById">
    <!--HEADER-->
    <fvview-info [fv]="fv" data-cy="fv-info"></fvview-info>
    <!--FV BODY-->
    <div class="row mb-1 ml-5 mr-2">
      <div class="col-9 mt-3">
        <div
          class="row main-container"
          style="flex-wrap: nowrap"
          data-cy="main-fv-row"
        >
          <!-- FV Screening History Details  -->
          <div class="col-6 main-container">
            <fvview-screening-events
              [fv]="fv"
              data-cy="fv-screening-events"
              #fvScreeningEvents
            ></fvview-screening-events>
          </div>
          <!-- Activity -->
          <div class="col-6 main-container">
            <fvview-activity
              [fv]="fv"
              [fars]="fars"
              data-cy="fv-activity"
              #fvActivity
            ></fvview-activity>
          </div>
        </div>
        <div class="row">
          <!-- LINKED FOREIGN NATIONALS -->
          <div class="col-6" *ngIf="linkedFVs?.content?.length && !showLinked">
            <mat-card appearance="outlined" class="linked-nationals-card mt-3">
              <div class="d-flex flex-wrap gap">
                <app-paginator
                  class="mb-2 w-100"
                  [showPaginator]="
                    linkedFVs.totalPages && linkedFVs.totalPages > 1
                      ? true
                      : false
                  "
                  [(filterParams)]="filterParams"
                  (filterParamsChange)="updateMetrics()"
                  [pageIndex]="linkedFVs.number"
                  [pageSize]="linkedFVs.size"
                  [totalElements]="linkedFVs.totalElements"
                  [hidePageSize]="true"
                  [showFirstLastButtons]="false"
                  [hideBorderTop]="true"
                  icon="account-multiple"
                  title="Linked Foreign Nationals"
                ></app-paginator>
              </div>
              <div class="d-flex container pb-2" style="overflow-x: auto">
                <ng-container *ngFor="let fn of linkedFVs?.content">
                  <app-fn-card
                    [fn]="fn"
                    class="mx-2"
                    [showPills]="false"
                    [hideMetadataFooter]="true"
                    [isLinkedNationals]="true"
                    [originalFnId]="fv.id"
                  ></app-fn-card>
                </ng-container>
              </div>
            </mat-card>
          </div>
          <!-- COMMENTS -->
          <div
            [class]="
              linkedFVs?.content?.length && !showLinked ? 'col-6' : 'col-12'
            "
          >
            <mat-card
              appearance="outlined"
              class="comment-card mt-3"
              [style]="fv?.comments?.length ? 'padding-bottom: 16px' : ''"
            >
              <app-comment-list
                [class]="fv?.comments?.length === 0 ? 'empty-comment-card' : ''"
                data-cy="fv-comments"
                #commentList
                parentType="FV"
                [comments]="fv.comments || []"
                [deletable]="fv.isDeletable"
                [editable]="fv.isEditable"
                [parentId]="fv.id"
              >
              </app-comment-list>
            </mat-card>
          </div>
        </div>
      </div>
      <div class="col-3 collapseable-section">
        <!--Passports-->
        <app-fvview-passports
          data-cy="fv-passports"
          #passports
          [showActions]="true"
          [editable]="fv.isEditable"
          [deletable]="fv.isDeletable"
          [passports]="fv.passports || []"
          [parentId]="fv.id"
        ></app-fvview-passports>
        <!--Visas-->
        <app-fvview-visa
          data-cy="fv-visas"
          #visas
          [editable]="fv.isEditable"
          [deletable]="fv.isDeletable"
          [showActions]="true"
          [visas]="fv.visas || []"
          [parentId]="fv.id"
        >
        </app-fvview-visa>
        <!--National IDs-->
        <app-fvview-national-id
          data-cy="fv-national-id"
          [fv]="fv"
          [editable]="fv.isEditable"
          [deletable]="fv.isDeletable"
        ></app-fvview-national-id>
        <!--Contact Info-->
        <app-fvview-contact
          data-cy="fv-contact"
          [fv]="fv"
          [editable]="fv.isEditable"
        ></app-fvview-contact>
        <!--Education-->
        <fvview-education [fv]="fv" data-cy="fv-education"></fvview-education>
        <!--Employment-->
        <fvview-employment
          data-cy="fv-employment"
          #employment
          [deletable]="fv.isDeletable"
          [editable]="fv.isEditable"
          [(employment)]="fv.employment"
          [fvId]="fv.id"
        ></fvview-employment>
        <!--Social Media-->
        <app-fvview-social-media
          data-cy="fv-social-media"
          #socialmedia
          [deletable]="fv.isDeletable"
          [editable]="fv.isEditable"
          [socialMedia]="fv.socialMedia || []"
          [id]="fv.id"
        >
        </app-fvview-social-media>
        <!--Identifying Information and Vehicle-->
        <app-fvview-identifying-vehicle
          data-cy="fv-identifying-vehicle"
          [fv]="fv"
          [editable]="fv.isEditable"
        ></app-fvview-identifying-vehicle>
        <!--Attachments Button - No Existing Attachments-->
        <app-attachments
          #attachments
          (countChange)="updateAttachmentCount($event)"
          [editable]="fv.isEditable"
          [deletable]="fv.isDeletable"
          *ngIf="fv?.id"
          parentType="FV"
          [parentId]="fv.id"
          [buttonStyle]="true"
        ></app-attachments>
        <!--Attachments Button - No Existing Attachments - Read Only-->
        <button
          class="mt-3 w-100"
          style="height: 48px"
          *ngIf="attachmentCount === 0 && !fv.isEditable"
          mat-raised-button
          matTooltip="Add Attachment"
          color="primary"
          disabled
        >
          <div class="d-flex align-items-center">
            <span>Add Attachment</span>
            <mat-icon class="ml-2" svgIcon="paperclip"></mat-icon>
          </div>
        </button>
        <!--Attachments Section - Attachments Exist-->
        <mat-expansion-panel
          class="flex-fill mt-3 mb-1"
          #attachmentPanel="matExpansionPanel"
          id="fv-view-attachment-panel"
          *ngIf="attachmentCount > 0"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="d-flex justify-content-between py-2">
              <span>Attachments</span>
              <mat-icon
                svgIcon="paperclip"
                [matBadge]="attachmentCount"
                matBadgeSize="small"
                matBadgeColor="accent"
              ></mat-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-divider class="panel-header-divider"></mat-divider>
          <div class="content attachment-body">
            <app-attachments
              data-cy="fv-attachments-tab"
              #attachments
              [deletable]="fv.isDeletable"
              [editable]="fv.isEditable"
              parentType="FV"
              [parentId]="fv.id"
              (countChange)="updateAttachmentCount($event)"
            ></app-attachments>
          </div>
        </mat-expansion-panel>
        <!--Additional Information - No Data-->
        <button
          class="mt-3 w-100"
          style="height: 48px"
          mat-raised-button
          matTooltip="Add Additional Information"
          color="primary"
          [disabled]="!fv.isEditable"
          *ngIf="!fv.otherNames || fv.otherNames.length === 0"
          (click)="
            additionalInformation.onAdd(); additionalInformationPanel.open()
          "
        >
          <div class="d-flex align-items-center">
            <span>Add Additional Information</span>
            <mat-icon
              class="ml-2"
              svgIcon="folder-information-outline"
            ></mat-icon>
          </div>
        </button>
        <!--Additional Information - Data Exists-->
        <mat-expansion-panel
          class="flex-fill mt-3 mb-1"
          #additionalInformationPanel="matExpansionPanel"
          id="fv-view-additional-information-panel"
          [hidden]="!fv.otherNames || fv.otherNames.length === 0"
        >
          <mat-expansion-panel-header>
            <mat-panel-title class="d-flex justify-content-between py-2">
              <span>Additional Information</span>
              <mat-icon
                svgIcon="folder-information-outline"
                [matBadge]="fv.otherNames?.length || 0"
                matBadgeSize="small"
                matBadgeColor="accent"
              ></mat-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-divider class="panel-header-divider mb-0"></mat-divider>
          <div class="content attachment-body additional-info-panel">
            <app-fv-view-additional-info
              #additionalInformation
              data-cy="fv-additional-info"
              [fv]="fv"
              [editable]="fv.isEditable"
            ></app-fv-view-additional-info>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</ng-container>

import { Component } from '@angular/core';
import { Role } from '@app/shared/models';
@Component({
  selector: 'app-graph-container',
  templateUrl: './graph-container.component.html',
  styleUrls: ['./graph-container.component.scss'],
})
export class GraphContainerComponent {
  showScreeningGraph: boolean = true;
  showOrgGraph: boolean = true;
  showStatusGraph: boolean = true;
  Role = Role;
}

import { Component, Input } from '@angular/core';
import { Colors } from '@shared/components';
import { FAR } from '@shared/models';

@Component({
  selector: 'app-far-header',
  templateUrl: './far-header.component.html',
  styleUrls: ['./far-header.component.scss'],
})
export class FarHeaderComponent {
  @Input() far: FAR;

  getVisitIcon(): string {
    if (this.far && this.far.foreignAccessRequestLocations) {
      if (this.far.foreignAccessRequestLocations.length < 10) {
        return (
          'numeric-' + this.far.foreignAccessRequestLocations.length + '-box'
        );
      } else if (this.far.foreignAccessRequestLocations.length > 9) {
        return 'numeric-9-plus-box';
      } else return 'numeric-0-box';
    } else return 'numeric-0-box';
  }
}

<div class="d-flex justify-content-between">
  <h2 mat-dialog-title>Advanced Search</h2>
</div>
<mat-dialog-content class="filter__form">
  <form #filterForm="ngForm">
    <div class="container">
      <div class="row">
        <ng-container *ngFor="let config of filterFields">
          <ng-container *appIfRoles="config.ifRole!">
            <ng-container *appIfConfig="config.ifConfig!">
              <ng-container *ngIf="!config.formHidden">
                <div class="col-{{ config.col ?? 6 }}">
                  <mat-form-field
                    *ngIf="config.type === FilterConfigType.INPUT"
                    appearance="outline"
                  >
                    <mat-label>{{ config.label }}</mat-label>
                    <input
                      id="advanced-filter-{{ config.name }}"
                      matInput
                      [name]="config.name"
                      [placeholder]="config.placeholder!"
                      [maxlength]="config.maxLength!"
                      [(ngModel)]="data[config.name]"
                      [attr.aria-label]="config.label"
                    />
                    <mat-icon
                      *ngIf="config.helpText?.length"
                      matSuffix
                      matTooltip="{{ config.helpText }}"
                      >help</mat-icon
                    >
                  </mat-form-field>
                  <mat-form-field
                    *ngIf="config.type === FilterConfigType.DATE"
                    appearance="outline"
                  >
                    <mat-label>{{ config.label }}</mat-label>
                    <input
                      id="advanced-filter-{{ config.name }}"
                      matInput
                      #dateControl="ngModel"
                      [matDatepicker]="datePicker"
                      [(ngModel)]="data[config.name]"
                      [name]="config.name"
                      [min]="config.min"
                      [max]="config.max"
                      [placeholder]="config.placeholder ?? 'MM/DD/YYYY'"
                      [attr.aria-label]="config.label"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="datePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                    <mat-error> Please add a Valid Date</mat-error>
                  </mat-form-field>
                  <mat-form-field
                    *ngIf="config.type === FilterConfigType.DATE_RANGE"
                    appearance="outline"
                  >
                    <mat-label>{{ config.label }}</mat-label>
                    <mat-date-range-input
                      [rangePicker]="dateRangePicker"
                      [min]="config.min"
                      [max]="config.max"
                    >
                      <input
                        id="advanced-filter-{{ config.name }}-start"
                        name="{{ config.name }}Start"
                        matStartDate
                        [(ngModel)]="data[config.name + 'Start']"
                        [placeholder]="config.placeholder ?? 'MM/DD/YYYY'"
                      />
                      <input
                        id="advanced-filter-{{ config.name }}-end"
                        name="{{ config.name }}End"
                        matEndDate
                        [(ngModel)]="data[config.name + 'End']"
                        [placeholder]="config.placeholder ?? 'MM/DD/YYYY'"
                      />
                    </mat-date-range-input>
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="dateRangePicker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker
                      #dateRangePicker
                    ></mat-date-range-picker>
                  </mat-form-field>
                  <app-country-select
                    *ngIf="config.type === FilterConfigType.COUNTRY"
                    id="advanced-filter-{{ config.name }}"
                    [multiple]="config.multiple!"
                    [name]="config.name"
                    [(ngModel)]="data[config.name]"
                    [placeholder]="config.placeholder!"
                    [label]="config.label"
                    [excludeUS]="config.excludeUS!"
                  >
                  </app-country-select>
                  <mat-form-field
                    *ngIf="config.type === FilterConfigType.SELECT"
                    appearance="outline"
                  >
                    <mat-label>{{ config.label }}</mat-label>
                    <mat-select
                      id="advanced-filter-{{ config.name }}"
                      [name]="config.name"
                      [(ngModel)]="data[config.name]"
                      [placeholder]="config.placeholder!"
                      [multiple]="config.multiple!"
                    >
                      <mat-option
                        *ngIf="!config.multiple"
                        [value]="null"
                      ></mat-option>
                      <mat-option
                        *ngFor="let opt of config.options"
                        [matTooltip]="resolveProp(opt, config.optTooltip, null)"
                        [value]="resolveProp(opt, config.optVal)"
                        >{{ resolveProp(opt, config.optLabel) }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                  <mat-slide-toggle
                    class="mt-2 pb-3"
                    color="primary"
                    *ngIf="config.type === FilterConfigType.TOGGLE"
                    id="advanced-filter-{{ config.name }}"
                    [name]="config.name"
                    [(ngModel)]="data[config.name]"
                    [matTooltip]="config.tooltip!"
                    >{{ config.label }}</mat-slide-toggle
                  >
                  <div
                    class="pb-3"
                    *ngIf="config.type === FilterConfigType.BUTTON_TOGGLE"
                  >
                    <button
                      *ngFor="let opt of config.options"
                      mat-flat-button
                      [ngClass]="{
                        'mat-primary':
                          (data[config.name] ?? null) ===
                          resolveProp(opt, config.optVal)
                      }"
                      (click)="
                        data[config.name] = resolveProp(opt, config.optVal)
                      "
                    >
                      {{ resolveProp(opt, config.optLabel) }}
                    </button>
                  </div>
                  <app-organization-select
                    *ngIf="config.type === FilterConfigType.ORG"
                    [name]="config.name"
                    [label]="config.label"
                    [parentsOnly]="config.parentsOnly!"
                    class="filter__org__select"
                    [(orgId)]="data[config.name]"
                    [(ngModel)]="filterRefObjs[config.name]"
                  >
                  </app-organization-select>
                  <app-user-select
                    *ngIf="config.type === FilterConfigType.USER"
                    [name]="config.name"
                    [label]="config.label"
                    class="filter__user__select"
                    [(userId)]="data[config.name]"
                    [(ngModel)]="filterRefObjs[config.name]"
                  >
                  </app-user-select>
                  <app-select-fv
                    *ngIf="config.type === FilterConfigType.FN"
                    id="advanced-filter-{{ config.name }}"
                    [name]="config.name"
                    [required]="false"
                    placeholder="Select a Foreign National"
                    [(fvId)]="data[config.name]"
                    [(ngModel)]="filterRefObjs[config.name]"
                    [hideInputDisplay]="true"
                    [label]="
                      config.label?.length
                        ? config.label
                        : 'Select a Foreign National'
                    "
                    [multiple]="false"
                    [clearable]="true"
                    [restrictCurrentOrganization]="
                      config.restrictCurrentOrganization!
                    "
                  ></app-select-fv>
                  <app-org-location-select
                    *ngIf="config.type === FilterConfigType.LOCATION_ID"
                    [name]="config.name"
                    [(locationId)]="data[config.name]"
                    [(ngModel)]="filterRefObjs[config.name]"
                    [clearable]="true"
                    [multiple]="false"
                    [showCreate]="false"
                  ></app-org-location-select>
                  <div *ngIf="config.type === FilterConfigType.USER_ROLE">
                    <fieldset>
                      <legend>
                        <h6>{{ config.label }}</h6>
                      </legend>
                      <div class="d-flex justify-content-center">
                        <app-roles-select-control
                          id="advanced-filter-{{ config.name }}"
                          [name]="config.name"
                          [(ngModel)]="data[config.name]"
                          [roles]="Roles"
                        ></app-roles-select-control>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
  <div>
    <button
      id="search-dialog-clear"
      mat-button
      color="secondary"
      (click)="clear()"
    >
      Clear
    </button>
  </div>
  <div>
    <button
      id="search-dialog-close"
      mat-button
      color="primary"
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      id="search-dialog-submit"
      mat-raised-button
      color="primary"
      (click)="submit()"
    >
      Submit
    </button>
  </div>
</mat-dialog-actions>

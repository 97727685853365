<div class="far-card__header px-2 pt-2 pb-1 component" *ngIf="far">
  <mat-card-title
    class="d-flex align-items-center gap-1 text-primary"
    style="max-height: 32px"
  >
    <span
      matTooltip="{{ far.eventTitle | titlecase | default }}"
      class="d-flex"
    >
      {{ far.eventTitle | titlecase | shorten: 28:"..." | default }}
    </span>
  </mat-card-title>
  <mat-card-subtitle
    class="d-flex align-items-center subtitle justify-content-between"
    ><div
      class="d-flex align-items-center"
      matTooltip="Purpose Type: {{ far.purposeType | titlecase | default }}"
    >
      <mat-icon
        svgIcon="assistant"
        class="mr-2"
        style="height: 19px; width: 19px"
        color="primary"
      ></mat-icon>
      {{ far.purposeType | titlecase | default }}
    </div>
    <div
      class="d-flex align-items-center mr-2"
      matTooltip="Number of Visits"
      *ngIf="far.foreignAccessRequestLocations?.length !== 1"
    >
      <mat-icon
        [svgIcon]="getVisitIcon()"
        class="mr-1"
        style="height: 19px; width: 19px"
        color="primary"
      ></mat-icon>
      <span>Visits</span>
    </div>
  </mat-card-subtitle>

  <div class="d-flex 2-col"></div>
</div>

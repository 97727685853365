import { Component, Input, OnDestroy } from '@angular/core';
import { DialogData, ForeignVisitor, Role } from '@app/shared/models';
import { BiographicDialogComponent } from '../biographic-dialog/biographic-dialog.component';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  FvService,
  AlertService,
  ScreeningService,
} from '@app/shared/services';
import { ClassificationModel } from '@app/shared/models/classification.model';
import { ConfirmDialogComponent } from '@app/shared/components';
import { Subject, takeUntil } from 'rxjs';
import {
  FVEditRoles,
  FVRescreenRoles,
} from '@app/shared/models/role-permissions';

@Component({
  selector: 'fvview-info',
  templateUrl: 'fvinfo.component.html',
  styleUrls: ['fvinfo.component.scss'],
})
export class FvInfoComponent implements OnDestroy {
  @Input() fv: ForeignVisitor;
  FVRescreenRoles = FVRescreenRoles;
  Role = Role;
  FVEditRoles = FVEditRoles;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    public alert: AlertService,
    private fvService: FvService,
    private screeningService: ScreeningService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onEdit(): void {
    this.openDialog({ mode: 'update', model: this.fv });
  }

  openDialog(dialogData: DialogData<ForeignVisitor>): void {
    const dialogConfig: MatDialogConfig = {
      data: dialogData,
      disableClose: true,
      width: '600px',
      autoFocus: '#submit-button',
    };
    this.dialog.open(BiographicDialogComponent, dialogConfig);
  }

  onClassify(model: ClassificationModel) {
    this.fvService
      .save(model as ForeignVisitor)
      .subscribe((fv: ForeignVisitor) => {
        this.alert.successAlert(
          `Updated Classification for ${fv.givenName} ${fv.surname}`
        );
      });
  }

  openConfirmationDialog(
    fv: ForeignVisitor
  ): MatDialogRef<ConfirmDialogComponent> {
    let data = {
      icon: 'alert-octagon',
      color: 'warn',
      title: `ARE YOU SURE?`,
      message: `This will create a new screening request for ${fv.givenName} ${fv.surname}.`,
      performAction: () => this.rescreen(fv),
      confirmText: 'Confirm',
    };

    return this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '600px',
      data,
    });
  }

  rescreen(fv: ForeignVisitor) {
    this.screeningService
      .rescreen({ id: fv.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }
}

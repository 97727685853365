import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import {
  AccountStatus,
  FilterParams,
  User,
  UserPreferenceBlob,
} from '@app/shared/models';
import {
  CrudService,
  Repository,
  UserService,
  UserSortFieldLabels,
} from '@app/shared/services';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import { debounceTime, startWith, switchMap, tap, take } from 'rxjs/operators';
import {
  UserTableColumns,
  UserTableName,
} from './accounts-collection/account-user-table/account-user-table.component';

@Component({
  selector: 'app-admin-accounts',
  templateUrl: 'accounts.component.html',
  styleUrls: ['accounts.component.scss'],
  providers: [Repository, { provide: CrudService, useExisting: UserService }],
})
export class AccountsComponent implements OnInit {
  loading: boolean = false;
  refresh$ = this.repo.refresh$.pipe(startWith({}));
  users$ = this.refresh$.pipe(
    switchMap(() => {
      return this.repo.collection$;
    }),
    tap((_) => {
      this.loading = false;
    })
  );
  queryParamsArray: Array<string> = [];
  filterParams: FilterParams = this.initParams();

  hasUserPreference: boolean = false;
  userPreference: UserPreferenceBlob | undefined;

  get queryParamsSnapshot() {
    return this.repo.getQueryParamsSnapshot('accountStatus', 'roles');
  }

  constructor(
    private repo: Repository<User>,
    private auth: AuthService,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.queryParamsArray.length !== 0
      ? (this.filterParams = this.repo.defaultFilterParams)
      : Object.assign(this.filterParams, this.queryParamsSnapshot);
    if (!this.isFilterParams() && this.filterParams['sortBy'] !== 'lastLogin') {
      this.filterParams.accountStatus = [
        AccountStatus.Active,
        AccountStatus.PreRegistered,
      ];
      this.getUserPreferences();
    }
    this.onFilterParamsChange(this.filterParams);
  }

  getUserPreferences() {
    this.userPreferenceService
      .getCurrentUserId(this.auth?.getUser()?.id!)
      .pipe(debounceTime(1000), take(1))
      .subscribe(
        (result) => {
          if (result !== null && result !== undefined) {
            this.hasUserPreference = true;
            this.userPreference = JSON.parse(result.userPreferenceBlob!);
            let tabDetails = this.userPreference?.tabs.at(5);
            let adminArray: Array<string> = [];
            tabDetails?.tabColumns.filter((x) => {
              if (x.selected === true) {
                adminArray.push(x.label);
              }
            });

            Object.entries(UserSortFieldLabels).forEach((key, value) => {
              if (adminArray.includes(key[1])) {
                this.queryParamsArray.push(key[0]);
              }
            });
            if (
              this.userPreference !== null &&
              this.userPreference !== undefined
            ) {
              this.userPreferenceService.loadDisplayColumns(
                UserSortFieldLabels,
                adminArray,
                UserTableColumns,
                UserTableName,
                this.auth?.getUser()?.roles!,
                this.auth.getUser()?.networkDomain!
              );
            }
          }
        },
        (err) => {}
      );
  }

  // Initialize the filter Params for User Accounts with non-Inactive Users.
  initParams(): FilterParams {
    this.filterParams = this.repo.defaultFilterParams;
    return this.filterParams;
  }

  isFilterParams() {
    if (
      this.filterParams.surname ||
      this.filterParams.givenName ||
      this.filterParams.organizationId ||
      this.filterParams.emailAddress ||
      (this.filterParams.accountStatus &&
        this.filterParams.accountStatus.length > 0) ||
      (this.filterParams.roles && this.filterParams.roles.length > 0)
    ) {
      return true;
    }
    return false;
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.getUserPreferences();
    if (this.hasUserPreference) {
      this.filterParams = this.repo.defaultFilterParams;
      this.hasUserPreference = false;
    } else {
      this.filterParams = filterParams;
    }
    this.repo.navigateWithParams(this.filterParams);
  }
}

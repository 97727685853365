import { NgModule } from '@angular/core';
import {
  FormGroup,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { MatSortModule } from '@angular/material/sort';
import { UserPreferencesRoutingModule } from './user-preferences-routing.module';
import { UserPreferencesComponent } from './user-preferences.component';
import { OrgTabCheckboxComponent } from './org-tab-checkbox/org-tab-checkbox.component';
import { FarTabCheckboxComponent } from './far-tab-checkbox/far-tab-checkbox.component';
import { FnTabCheckboxComponent } from './fn-tab-checkbox/fn-tab-checkbox.component';
import { AdminTabCheckboxComponent } from './admin-tab-checkbox/admin-tab-checkbox.component';
import { LocationTabCheckboxComponent } from './location-tab-checkbox/location-tab-checkbox.component';
import { ScreeningTabCheckboxComponent } from './screening-tab-checkbox/screening-tab-checkbox.component';

@NgModule({
  declarations: [
    UserPreferencesComponent,
    OrgTabCheckboxComponent,
    FarTabCheckboxComponent,
    FnTabCheckboxComponent,
    AdminTabCheckboxComponent,
    LocationTabCheckboxComponent,
    ScreeningTabCheckboxComponent,
  ],
  imports: [
    SharedModule,
    UserPreferencesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class UserPreferencesModule {}

<app-fv-view-info-banner [fv]="reviewFV"></app-fv-view-info-banner>
<div class="fv-review-container">
  <div class="row">
    <!-- Identification -->
    <div class="col">
      <h5 class="review-header context-title-text">Identification</h5>
      <div class="outline-container">
        <div
          class="review-container"
          [style]="reviewFV?.vip ? 'padding-top: 10px' : ''"
        >
          <div
            *ngIf="reviewFV?.vip"
            mat-raised-button
            class="d-flex bg-accent justify-content-center p-1 rounded mb-0"
            matTooltip="Expedited Foreign National"
          >
            <mat-icon class="mr-1">stars</mat-icon
            ><span class="vip-text">Expedited</span>
          </div>
          <div class="data-row">
            <div class="data-key">Given Name</div>
            <div class="data-value">{{ reviewFV?.givenName }}</div>
          </div>
          <div class="data-row">
            <div class="data-key">Surname</div>
            <div class="data-value">{{ reviewFV?.surname }}</div>
          </div>
          <div class="data-row">
            <div class="data-key">Gender</div>
            <div class="data-value">{{ reviewFV?.gender | titlecase }}</div>
          </div>
          <div class="data-row">
            <div class="data-key">Date of Birth</div>
            <div class="data-value">
              {{ reviewFV?.dateOfBirth | date }}
            </div>
          </div>
          <div class="data-row">
            <div class="data-key">Country of Birth</div>
            <div
              class="data-value"
              *ngIf="reviewFV?.birthCountry as birthCountry"
            >
              {{ birthCountry | country }}
            </div>
          </div>
          <div class="data-row">
            <div class="data-key">Country of Citizenship</div>
            <div
              class="data-value"
              *ngIf="reviewFV?.citizenshipCountry as citizenshipCountry"
            >
              {{ citizenshipCountry | country }}
            </div>
          </div>
          <div class="data-row">
            <div class="data-key">Country of Residence</div>
            <div
              class="data-value"
              *ngIf="reviewFV?.residenceCountry as residenceCountry"
            >
              {{ residenceCountry | country }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Contact -->
    <div class="col">
      <h5 class="review-header context-title-text">
        Foreign National's Contact
      </h5>
      <div class="outline-container">
        <div class="review-container">
          <div class="data-row">
            <div class="data-key">Email Address</div>
            <div class="data-value" *ngIf="fvEmail?.emailAddress">
              {{ fvEmail?.emailAddress }}
            </div>
          </div>
          <div class="data-row">
            <div class="data-key">Phone Number</div>
            <div class="data-value" *ngIf="fvPhone.number">
              {{ fvPhone | phoneNumber }}
            </div>
          </div>
          <div class="data-row">
            <div class="data-key">Address Line 1</div>
            <div class="data-value">{{ reviewFV?.address?.line1 }}</div>
          </div>
          <div class="data-row">
            <div class="data-key">Address Line 2</div>
            <div class="data-value">
              {{ reviewFV?.address?.line2 }}
            </div>
          </div>
          <div class="data-row">
            <div class="data-key">City</div>
            <div class="data-value">{{ reviewFV?.address?.city }}</div>
          </div>
          <div class="data-row">
            <div class="data-key">State/Province</div>
            <div class="data-value">{{ reviewFV?.address?.stateProvince }}</div>
          </div>
          <div class="data-row">
            <div class="data-key">Postal Code</div>
            <div class="data-value">{{ reviewFV?.address?.postalCode }}</div>
          </div>
          <div class="data-row">
            <div class="data-key">Country</div>
            <div class="data-value">{{ reviewFV?.address?.countryCode }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Travel Information -->
  <h5 class="review-header context-title-text">Travel</h5>
  <div class="outline-container">
    <div class="row">
      <!-- Passport -->
      <div class="col">
        <div class="review-container">
          <h5 class="review-header">Passport Information</h5>
          <div class="data-row">
            <div class="data-key">Passport Number</div>
            <div class="data-value">{{ passport?.number }}</div>
          </div>
          <div class="data-row">
            <div class="data-key">Passport Type</div>
            <div class="data-value">{{ passport?.type }}</div>
          </div>
          <div class="data-row">
            <div class="data-key">Passport Issuing Country</div>
            <div
              class="data-value"
              *ngIf="passport?.issuingCountryCode as issuingCountryCode"
            >
              {{ issuingCountryCode | country }}
            </div>
          </div>
          <div class="data-row">
            <div class="data-key">Passport Issuing Authority</div>
            <div class="data-value">
              {{ passport?.issueAuthority }}
            </div>
          </div>
          <div class="data-row">
            <div class="data-key">Passport Issue Date</div>
            <div class="data-value">
              {{ passport?.issueDate | date: "mediumDate" }}
            </div>
          </div>
          <div class="data-row">
            <div class="data-key">Passport Expiration Date</div>
            <div class="data-value">
              {{ passport?.expirationDate | date: "mediumDate" }}
            </div>
          </div>
        </div>
      </div>
      <!-- Visa -->
      <div class="col">
        <div class="review-container">
          <h5 class="review-header">Visa Information</h5>
          <div class="data-row">
            <div class="data-key">Visa Number</div>
            <div class="data-value">
              {{ visa?.number }}
            </div>
          </div>
          <div class="data-row">
            <div class="data-key">Visa Type</div>
            <div class="data-value">{{ visa?.type }}</div>
          </div>
          <div class="data-row">
            <div class="data-key">Visa Issue Location</div>
            <div class="data-value">
              {{ visa?.issueLocation }}
            </div>
          </div>
          <div class="data-row">
            <div class="data-key">Visa Expiration Date</div>
            <div class="data-value">
              {{ visa?.expirationDate | date: "mediumDate" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FAR Information -->
  <ng-container *ngIf="reviewFV?.foreignAccessRequests?.length">
    <h5 class="review-header context-title-text">FARs</h5>
    <div class="outline-container h-auto p-3">
      <div class="row">
        <div
          class="col-auto my-2 px-2"
          *ngFor="let far of reviewFV?.foreignAccessRequests!"
        >
          <app-new-far-card
            [showRollups]="false"
            [far]="far"
          ></app-new-far-card>
        </div>
      </div>
    </div>
  </ng-container>
</div>

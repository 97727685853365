<app-page-header title="Screening Request Creation"></app-page-header>
<div class="d-flex m-5 justify-content-center" *ngIf="!fv">
  <mat-spinner></mat-spinner>
</div>
<form #fvForm="ngForm" *ngIf="fv" class="container">
  <mat-card appearance="outlined" class="mt-3">
    <mat-card-header>
      <mat-card-title></mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <app-classification-banner
        [classificationModel]="fv"
      ></app-classification-banner>
      <mat-horizontal-stepper
        #stepper
        (selectionChange)="onStep($event)"
        aria-label="Foreign National Information Tabs"
      >
        <ng-template matStepperIcon="edit">
          <mat-icon>done</mat-icon>
        </ng-template>

        <!-- Baseline Information Step-->
        <mat-step
          tabindex="0"
          #baselineInfoStep="matStep"
          ngModelGroup="baselineInfo"
          [stepControl]="fvForm.controls.baselineInfo"
          aria-label="Fv baseline check"
          [hasError]="false"
          [completed]="baselineInfoStep.interacted"
        >
          <ng-template matStepLabel>Baseline</ng-template>
          <div class="row">
            <div class="d-flex flex-row col">
              <div class="col-12 d-flex flex-column pr-0 pl-2">
                <h5 class="mb-0">Foreign Access Request Assignment</h5>
                <div
                  class="d-flex justify-content-between mt-2 align-items-center"
                >
                  <h6 class="mb-0">Available Foreign Access Requests</h6>
                  <button
                    data-cy="new-far-button"
                    mat-button
                    color="primary"
                    appCreateFar
                    (farCreated)="
                      updateFarList($event); farSelect.updateSelection($event)
                    "
                  >
                    <div class="d-flex align-items-center gap-1">
                      <mat-icon svgIcon="plus-circle-outline"></mat-icon>
                      <span>New FAR</span>
                    </div>
                  </button>
                </div>
                <form #farsInfoForm="ngForm" class="mt-2">
                  <app-select-far
                    #farSelect
                    [(ngModel)]="fv.foreignAccessRequests"
                    name="farsToAssociate"
                    [hideInputDisplay]="true"
                    [multiple]="true"
                    [clearable]="false"
                    ngDefaultControl
                  ></app-select-far>
                </form>
                <mat-divider class="far-section-divider"></mat-divider>
                <h5>
                  Selected FARs:<span>
                    {{ fv.foreignAccessRequests?.length || 0 }}</span
                  >
                </h5>
                <div class="row">
                  <div
                    class="col-auto mt-3 mb-1 px-1"
                    *ngFor="let far of fv.foreignAccessRequests"
                  >
                    <app-new-far-card
                      [showRollups]="false"
                      [far]="far"
                    ></app-new-far-card>
                  </div>
                  <div
                    class="col d-flex flex-column mh-270"
                    *ngIf="!fv.foreignAccessRequests?.length"
                  >
                    <mat-icon
                      svgIcon="calendar-blank"
                      class="md-64 flex-fill align-self-center"
                    ></mat-icon>
                  </div>
                </div>
              </div>
            </div>
            <mat-divider vertical></mat-divider>
            <app-visitor-baseline
              class="col-6"
              #baselineInfoControl
              [(ngModel)]="fv"
              [(passport)]="passport"
              name="baseline"
            ></app-visitor-baseline>
          </div>
        </mat-step>

        <!--Biographic Information Step-->
        <mat-step
          tabindex="1"
          #foreignVisitorInfoStep="matStep"
          ngModelGroup="foreignVisitorInfo"
          [stepControl]="fvForm.controls.foreignVisitorInfo"
          aria-label="biographic"
          errorMessage="Please review errors"
        >
          <ng-template matStepLabel>Identification</ng-template>
          <app-biographic
            #bio
            [(ngModel)]="fv"
            name="biographic"
          ></app-biographic>
        </mat-step>

        <!-- Travel Information Step-->
        <mat-step
          tabindex="2"
          #travelInfoStep="matStep"
          ngModelGroup="travelInfo"
          [stepControl]="fvForm.controls.travelInfo"
          aria-label="Travel information"
          errorMessage="Please review errors"
        >
          <ng-template matStepLabel>Travel</ng-template>
          <!-- Passport Information-->
          <div class="d-flex">
            <h5 class="mt-3">Passport Information</h5>
            <app-passport-help></app-passport-help>
          </div>
          <app-passport
            #fvPassport
            [(ngModel)]="passport"
            [minIssueDate]="fv.dateOfBirth ? fv.dateOfBirth : undefined"
            name="passport"
          ></app-passport>

          <!-- Visa Information-->
          <h5>Visa Information</h5>

          <app-visa #fvVisa [(ngModel)]="visa" name="visa"></app-visa>
        </mat-step>

        <!-- Contact Information Step-->
        <mat-step
          tabindex="3"
          #contactInfoStep="matStep"
          ngModelGroup="contactInfo"
          [stepControl]="fvForm.controls.contactInfo"
          aria-label="Foreign National contact"
          errorMessage="Please review errors"
          [hasError]="fvContact?.contactForm?.invalid"
          [completed]="contactInfoStep.interacted && !contactInfoStep.hasError"
        >
          <ng-template matStepLabel>Contact</ng-template>
          <form #contactInfoForm="ngForm">
            <app-contact
              #fvContact
              [(ngModel)]="fv"
              [fvEmail]="fvEmail"
              [fvPhone]="fvPhone"
              name="contact"
            ></app-contact>
            <app-address
              legend="Address"
              [(ngModel)]="address"
              name="address"
              #addressControl
            ></app-address>
          </form>
        </mat-step>

        <!-- Review Information Step-->
        <mat-step tabindex="4">
          <ng-template matStepLabel>Review</ng-template>
          <app-fv-summary
            [reviewFV]="fv"
            [passport]="passport"
            [address]="address"
            [visa]="visa"
            [fvEmail]="fvEmail"
            [fvPhone]="fvPhone"
            #summary
          ></app-fv-summary>
        </mat-step>
      </mat-horizontal-stepper>
    </mat-card-content>

    <!-- Card Footer-->
    <mat-card-footer>
      <mat-toolbar class="d-flex justify-content-between">
        <div>
          <!-- EXIT BUTTON -->
          <button mat-stroked-button [routerLink]="'/fvs'">Exit</button>
          <!-- CLASSIFY BUTTON -->
          <button
            mat-flat-button
            type="button"
            class="ml-3 mr-3"
            id="fv-create-classify-btn"
            appClassify
            *appClassifyShow
            [(classificationModel)]="fv"
            color="accent"
          >
            Classify
          </button>
        </div>
        <div>
          <!-- BACK BUTTON -->
          <button
            *ngIf="stepper.selectedIndex !== 0"
            mat-stroked-button
            (click)="goBack(stepper)"
            color="primary"
            class="mr-3"
          >
            <mat-icon>keyboard_arrow_left</mat-icon>
            Back
          </button>
          <!-- NEXT BUTTON -->
          <button
            *ngIf="stepper.selectedIndex !== stepper.steps.length - 1"
            mat-stroked-button
            type="button"
            (click)="goForward(stepper)"
            color="primary"
          >
            Next
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
          <!-- SUBMIT BUTTON -->
          <button
            *ngIf="stepper.selectedIndex === stepper.steps.length - 1"
            [disabled]="!fv.givenName || !fv.surname || busy"
            mat-raised-button
            (click)="onCreate()"
            formaction="visitor-success-dialog.html"
            color="primary"
          >
            <span *ngIf="!busy">Submit</span>
            <mat-spinner
              class="mr-1 ml-1"
              *ngIf="busy"
              color="primary"
              diameter="35"
            ></mat-spinner>
          </button>
        </div>
      </mat-toolbar>
    </mat-card-footer>
  </mat-card>
</form>

<div
  class="fn-card__metadata component text-primary"
  [class]="tableDisplay ? 'background-white' : ''"
>
  <div class="d-flex justify-content-around pt-2" *ngIf="!tableDisplay">
    <app-field label="created by" [value]="createdByFullName"></app-field>
    <app-field
      label="organization"
      [value]="owningOrgShortName"
      [matTooltip]="owningOrgName"
      matTooltipPositionAtOrigin
    ></app-field>
    <app-field label="network" [value]="networkDoimain"></app-field>
  </div>
  <div
    class="meta-icon-list d-flex pt-3 px-2 justify-content-between"
    *ngIf="!hideMetadataFooter"
  >
    <div
      class="meta-icon-list__item read-only"
      *ngIf="!fn.isEditable && !tableDisplay"
    >
      <mat-icon svgIcon="pencil-off-outline" matTooltip="Read Only"></mat-icon>
    </div>
    <div class="flex-fill" *ngIf="!tableDisplay"></div>
    <div class="meta-icon-list__item" *ngIf="tableDisplay">
      <mat-icon
        [svgIcon]="'alpha-' + fn.networkDomain?.toLowerCase() + '-box'"
        matTooltip="Network"
        aria-hidden="false"
      ></mat-icon>
    </div>
    <div class="meta-icon-list__item">
      <mat-icon
        svgIcon="account-multiple"
        [matBadge]="linkedVisitors"
        matBadgeColor="accent"
        matBadgeSize="small"
        matTooltip="Linked Nationals: {{ linkedVisitors }}"
        aria-hidden="false"
      ></mat-icon>
    </div>
    <div class="meta-icon-list__item">
      <mat-icon
        *ngIf="activeFarCount && farScreeningEligibility"
        svgIcon="map-marker-multiple-outline"
        [matBadge]="activeFarCount"
        matBadgeColor="accent"
        matBadgeSize="small"
        matTooltip="Active FARs: {{ activeFarCount }}"
        aria-hidden="false"
      ></mat-icon>
      <mat-icon
        class="red"
        *ngIf="!activeFarCount && farScreeningEligibility"
        svgIcon="map-marker-alert"
        [matBadge]="activeFarCount"
        matBadgeColor="warn"
        matBadgeSize="small"
        matTooltip="An Active FAR is Required for Screening"
        aria-hidden="false"
      ></mat-icon>
      <mat-icon
        *ngIf="!farScreeningEligibility"
        svgIcon="map-marker-multiple-outline"
        [matBadge]="farCount"
        matBadgeColor="accent"
        matBadgeSize="small"
        matTooltip="Total FARs: {{ farCount }}"
        aria-hidden="false"
      ></mat-icon>
    </div>
    <div class="meta-icon-list__item">
      <mat-icon
        svgIcon="paperclip"
        [matBadge]="attachmentCount"
        matBadgeColor="accent"
        matBadgeSize="small"
        matTooltip="Attachments: {{ attachmentCount }}"
        aria-hidden="false"
      ></mat-icon>
    </div>
    <div class="meta-icon-list__item">
      <mat-icon
        svgIcon="comment-outline"
        [matBadge]="comments"
        matBadgeColor="accent"
        matBadgeSize="small"
        matTooltip="Comments: {{ comments }}"
        aria-hidden="false"
      ></mat-icon>
    </div>
    <div *ngIf="fn.deletionDate" class="meta-icon-list__item">
      <mat-icon
        svgIcon="delete-clock-outline"
        [matBadge]="fn.deletionDate | daysUntil"
        matBadgeColor="accent"
        matBadgeSize="small"
        matTooltip="Days until Delete: {{ fn.deletionDate | daysUntil }}"
        aria-hidden="false"
      ></mat-icon>
    </div>
  </div>
</div>

<mat-toolbar class="bg-white user-preference-toolbar">
  <mat-icon svgIcon="account-cog-outline"> </mat-icon>
  <h1 class="headerStyles" style="font: 400 24px / 32px Roboto, sans-serif">
    User Preferences
  </h1>
</mat-toolbar>
<form
  [formGroup]="userPreferenceForm"
  id="userPreferenceForm"
  (ngSubmit)="onSubmit()"
  class="d-flex flex-column mt-2 mx-5"
>
  <h3 class="font-weight-bold text-primary"><mat-label> View </mat-label></h3>
  <mat-label class="py-2">Select Starting Page</mat-label>
  <div class="mt-3 row">
    <mat-form-field class="dropdown col-5">
      <mat-select
        formControlName="prefviews"
        (opened)="(preferenceView)"
        placeholder="Select a View"
        name="preferenceView"
        (selectionChange)="selectedView($event.value)"
        [(value)]="preferenceView"
      >
        <mat-option
          [value]="preferenceView"
          *ngFor="let preferenceView of displayViews; let i = index"
        >
          {{ preferenceView }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-tab-group
      dynamicHeight
      [preserveContent]="true"
      mat-stretch-tabs="true"
      mat-align-tabs="start"
      (selectedTabChange)="onTabChange($event)"
      (selectedIndexChange)="setTabIndex($event)"
      [(selectedIndex)]="tabIndex"
      #tabGroup
      id="preferenceTabGroup"
    >
      <mat-tab label="Foreign Nationals (FN)"
        ><app-fn-tab-checkbox
          #fnTab
          [checkedColumns]="savedUserPreference?.selectedViewColumns!"
          [tabTitle]="tabTitle"
          [previewName]="preferenceView"
          [updated]="isUpdated"
        ></app-fn-tab-checkbox
      ></mat-tab>
      <mat-tab label="Foreign Access Request (FAR)"
        ><app-far-tab-checkbox
          #farTab
          [checkedColumns]="savedUserPreference?.selectedViewColumns!"
          [tabTitle]="tabTitle"
          [previewName]="preferenceView"
          [updated]="isUpdated"
        ></app-far-tab-checkbox
      ></mat-tab>
      <mat-tab label="Screening Review" #screenTab *appIfRoles="ScreeningRoles"
        ><app-screening-tab-checkbox
          [checkedColumns]="savedUserPreference?.selectedViewColumns!"
          [tabTitle]="tabTitle"
          [userRoles]="userRoles"
          [previewName]="preferenceView"
          [updated]="isUpdated"
        ></app-screening-tab-checkbox
      ></mat-tab>
      <mat-tab label="Organizations" #orgTab *appIfRoles="LocViewRoles"
        ><app-org-tab-checkbox
          [checkedColumns]="savedUserPreference?.selectedViewColumns!"
          [userRoles]="userRoles"
          [tabTitle]="tabTitle"
          [previewName]="preferenceView"
          [updated]="isUpdated"
        ></app-org-tab-checkbox
      ></mat-tab>
      <mat-tab label="Locations" #locTab *appIfRoles="LocViewRoles"
        ><app-location-tab-checkbox
          [checkedColumns]="savedUserPreference?.selectedViewColumns!"
          [tabTitle]="tabTitle"
          [userRoles]="userRoles"
          [previewName]="preferenceView"
          [updated]="isUpdated"
        ></app-location-tab-checkbox
      ></mat-tab>
      <mat-tab label="User Administration" #userTab *appIfRoles="AdminViewRoles"
        ><app-admin-tab-checkbox
          [checkedColumns]="savedUserPreference?.selectedViewColumns!"
          [tabTitle]="tabTitle"
          [userRoles]="userRoles"
          [previewName]="preferenceView"
          [updated]="isUpdated"
        ></app-admin-tab-checkbox
      ></mat-tab>
    </mat-tab-group>
  </div>
</form>
<mat-toolbar class="flex-container bottom-bar">
  <button mat-button color="primary" (click)="cancel()">Cancel</button>
  <button
    mat-raised-button
    id="save-user-preference-changes"
    color="primary"
    (click)="saveChanges()"
  >
    Save Changes
  </button>
</mat-toolbar>

import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { PageableCollection } from '@app/shared/models/pageable-collection.model';
import {
  FilterParams,
  OrgLocation,
  UserPreferenceBlob,
} from '@app/shared/models';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import {
  CrudService,
  OrgLocationService,
  OrgLocationSortLabels,
  Repository,
} from '@app/shared/services';
import { debounceTime, take, tap } from 'rxjs/operators';
import { AuthService } from '@app/auth/auth.service';
import {
  LocationTableColumns,
  LocationTableName,
} from '../collection/location-collection-table/location-collection-table.component';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';

@Component({
  selector: 'app-locations',
  templateUrl: 'locations.component.html',
  styleUrls: ['locations.component.scss'],
  providers: [
    Repository,
    { provide: CrudService, useClass: OrgLocationService },
  ],
})
export class LocationsComponent implements OnInit, AfterContentChecked {
  loading: boolean = false;
  userPreference: UserPreferenceBlob | undefined;
  filterParams: FilterParams = new FilterParams({
    pageNum: 0,
    pageSize: 20,
    sortBy: 'name',
    direction: 'ASC',
  });
  locations$: Observable<PageableCollection<OrgLocation>> =
    this.repo.collection$.pipe(
      tap((_) => {
        this.loading = false;
      })
    );

  constructor(
    private repo: Repository<OrgLocation>,
    private changeDetect: ChangeDetectorRef,
    private auth: AuthService,
    private userPreferenceService: UserPreferenceService
  ) {}
  ngOnInit(): void {
    Object.assign(this.filterParams, this.queryParamsSnapshot);
    this.onFilterParamsChange(this.filterParams);
    this.userPreferenceService
      .getCurrentUserId(this.auth?.getUser()?.id!)
      .pipe(take(1), debounceTime(1000))
      .subscribe(
        (result) => {
          if (result !== null && result !== undefined) {
            this.userPreference = JSON.parse(result.userPreferenceBlob);
            let tabDetails = this.userPreference?.tabs.at(4);
            let locationArray: Array<string> = [];
            tabDetails?.tabColumns.filter((x) => {
              if (x.selected === true) {
                locationArray.push(x.label);
              }
            });
            if (
              this.userPreference !== null &&
              this.userPreference !== undefined
            ) {
              this.userPreferenceService.loadDisplayColumns(
                OrgLocationSortLabels,
                locationArray,
                LocationTableColumns,
                LocationTableName,
                this.auth?.getUser()?.roles!,
                this.auth.getUser()?.networkDomain!
              );
            }
          }
        },
        (err) => {}
      );
  }

  ngAfterContentChecked(): void {
    this.changeDetect.detectChanges();
  }

  get queryParamsSnapshot(): Params {
    return this.repo.getQueryParamsSnapshot();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.navigateWithParams(filterParams);
  }
}

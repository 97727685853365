import { Component, OnInit } from '@angular/core';
import {
  UserTableColumns,
  UserTableName,
} from '@app/admin/accounts/accounts-collection/account-user-table/account-user-table.component';
import { AuthService } from '@app/auth/auth.service';
import { FilterParams, User, UserPreferenceBlob } from '@app/shared/models';
import {
  CrudService,
  Repository,
  UserService,
  UserSortFieldLabels,
} from '@app/shared/services';
import { UserPreferenceService } from '@app/shared/services/user-preference.service';
import { debounceTime, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-accounts-workspace',
  templateUrl: './accounts-workspace.component.html',
  providers: [Repository, { provide: CrudService, useExisting: UserService }],
})
export class AccountsWorkspaceComponent implements OnInit {
  userPreference: UserPreferenceBlob | undefined;
  loading: boolean = false;
  filterParams: FilterParams = this.repo.defaultFilterParams;
  users$ = this.repo.collection$.pipe(
    tap((_) => {
      this.loading = false;
    })
  );

  constructor(
    private repo: Repository<User>,
    private auth: AuthService,
    private userPreferenceService: UserPreferenceService
  ) {}

  ngOnInit(): void {
    this.onFilterParamsChange(this.filterParams);
    this.getUserPreferences();
  }

  onFilterParamsChange(filterParams: FilterParams) {
    this.loading = true;
    this.filterParams = filterParams;
    this.repo.onFilterParamChange(this.filterParams);
  }

  getUserPreferences() {
    this.userPreferenceService
      .getCurrentUserId(this.auth?.getUser()?.id!)
      .pipe(take(1), debounceTime(1000))
      .subscribe(
        (result) => {
          if (result !== null && result !== undefined) {
            this.userPreference = JSON.parse(result.userPreferenceBlob!);
            let tabDetails = this.userPreference?.tabs.at(5);
            let adminArray: Array<string> = [];
            tabDetails?.tabColumns.filter((x) => {
              if (x.selected === true) {
                adminArray.push(x.label);
              }
            });
            if (
              this.userPreference !== null &&
              this.userPreference !== undefined
            ) {
              this.userPreferenceService.loadDisplayColumns(
                UserSortFieldLabels,
                adminArray,
                UserTableColumns,
                UserTableName,
                this.auth?.getUser()?.roles!,
                this.auth.getUser()?.networkDomain!
              );
            }
          }
        },
        (err) => {}
      );
  }
}

import { HttpParams } from '@angular/common/http';
import { isEmpty, isBoolean, isNumber, isArray, isDate } from 'lodash';
import * as moment from 'moment';
import { Sort } from '@angular/material/sort';
import { Params } from '@angular/router';

export class FilterParams {
  [key: string]: any;
  search?: string;
  pageNum?: number;
  pageSize?: number;
  sort?: Sort;
  sortBy?: string;
  direction?: string;

  constructor(data: Partial<FilterParams> = {}) {
    Object.assign(this, data);
  }

  get isEmpty(): boolean {
    return !!Object.keys(this).find((k) => {
      const v = this[k];
      return !isBoolean(v) && !isNumber(v) && (!v || isEmpty(v));
    });
  }

  asParams(): Params {
    const httpParams = this.httpParams;
    return httpParams.keys().reduce((val, k) => {
      if (isArray(this[k])) {
        val[k] = httpParams.getAll(k);
      } else {
        val[k] = httpParams.get(k);
      }
      return val;
    }, {} as Params);
  }

  get httpParams(): HttpParams {
    let params = new HttpParams();
    Object.keys(this).forEach((key) => {
      let value = this[key];
      if (!isBoolean(value) && !isNumber(value) && (!value || isEmpty(value)))
        return;

      if (isDate(value) || moment.isMoment(value)) {
        value = moment(value).format('YYYY-MM-DD');
      }

      if (key === 'sort') {
        if (this.sort) {
          // We need to support both sort=<sort,dir> and sortBy=<sort>&direction=<dir>
          // as some services do it differently (i.e. Spring default vs custom sorting)
          value = `${this.sort?.active},${this.sort?.direction || 'asc'}`;
          params = params.append('sortBy', this.sort.active);
          params = params.append(
            'direction',
            this.sort?.direction.toUpperCase() || 'ASC'
          );
        }
      }

      if (isArray(value)) {
        value.forEach((v) => {
          params = params.append(`${key}`, v);
        });
      } else {
        params = params.set(key, value);
      }
    });
    return params;
  }

  get filtered(): boolean {
    return this.toString() !== new FilterParams().toString();
  }

  clone(): FilterParams {
    let data: FilterParams = new FilterParams();
    Object.assign(data, this);
    Object.keys(data).find((k) => {
      if (data[k] === null) {
        delete data[k];
      }
    });

    return data;
  }

  toString(): string {
    return this.httpParams.toString();
  }
}

// TODO: Remove once Paging implemented across views
export const allDataParams = (data: Partial<FilterParams> = {}) =>
  new FilterParams({ pageSize: 20, ...data });

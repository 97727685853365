import { Component, ViewChild, OnInit } from '@angular/core';
import { GraphContainerComponent } from '../graph-container/graph-container.component';
import { AuthService } from '@app/auth/auth.service';
import { take } from 'rxjs';
import { Role } from '@app/shared/models';

@Component({
  selector: 'app-metrics-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(private authService: AuthService) {}

  @ViewChild('graphContainer') graphContainer: GraphContainerComponent;
  showMetrics: boolean = true;
  Role = Role;
  displayDataList: any = [
    { label: 'Insights', checked: true },
    { label: 'Screening Results', checked: true },
  ];

  ngOnInit() {
    this.authService.user$?.pipe(take(1)).subscribe((user) => {
      if (user && user.roles) {
        if (user.roles.length > 1 || user.roles[0] !== Role.sv_org_user) {
          this.displayDataList.push({
            label: 'Screening Results by Organization',
            checked: true,
          });
        }
      }
      this.displayDataList.push({ label: 'Screening Status', checked: true });
    });
  }

  toggleAll(state: boolean) {
    for (let item of this.displayDataList) {
      item.checked = state;
    }
    this.graphContainer.showScreeningGraph = state;
    this.graphContainer.showOrgGraph = state;
    this.graphContainer.showStatusGraph = state;
  }

  toggleComponent(component: any) {
    if (component.label !== 'Insights') {
      if (component.label === 'Screening Results') {
        this.graphContainer.showScreeningGraph = component.checked;
      }
      if (component.label === 'Screening Results by Organization') {
        this.graphContainer.showOrgGraph = component.checked;
      }
      if (component.label === 'Screening Status') {
        this.graphContainer.showStatusGraph = component.checked;
      }
    }
  }
}

<div class="d-flex flex-column h-100 bulk-upload-container">
  <div mat-dialog-title class="d-flex flex-row mt-3">
    <span *ngIf="settings"
      >{{ settings.label }}
      <mat-icon
        *ngIf="settings.label === 'Foreign National Bulk Upload'"
        style="margin-bottom: -3px"
        [matTooltip]="generateFnTooltip()"
        >help</mat-icon
      ></span
    >
    <span class="flex-grow-1"></span>
    <button
      matTooltip="Download Templates"
      mat-icon-button
      [matMenuTriggerFor]="dialogMenu"
      *ngIf="settings.templates"
    >
      <mat-icon mimeType="excel"></mat-icon>
    </button>
    <mat-menu #dialogMenu="matMenu">
      <h6 class="p-2 text-primary bold">Download Templates</h6>
      <button
        mat-menu-item
        *ngFor="let tpl of settings.templates"
        (click)="download(tpl)"
      >
        <mat-icon svgIcon="download"></mat-icon>
        <span>{{ tpl.label }}</span>
      </button>
    </mat-menu>
  </div>
  <app-classification-banner
    class="d-block mb-2"
    [classificationModel]="classificationModel"
  ></app-classification-banner>
  <div class="container">
    <ng-container *ngIf="settings.adminOwnerOrgSelect as adminOwnerOrgSelect">
      <div class="row" *appIfRoles="Role.sv_admin">
        <app-organization-select
          class="col"
          [required]="adminOwnerOrgSelect ? adminOwnerOrgSelect : false"
          [(ngModel)]="owningOrg"
          name="owningOrgId"
        >
        </app-organization-select>
      </div>
    </ng-container>
    <ng-container *ngIf="data.type === 'FV' && farScreeningEligibility">
      <div class="full-width">
        <app-select-far
          #farSelect
          [(ngModel)]="farId"
          name="farsToAssociate"
          [hideInputDisplay]="true"
          [multiple]="true"
          [clearable]="false"
          ngDefaultControl
          [required]="farScreeningEligibility"
        ></app-select-far>
      </div>
    </ng-container>
    <div class="row">
      <div class="col">
        <div
          id="left"
          class="flex-grow-1"
          [ngClass]="{ 'full-width': !showErrors }"
          ng2FileDrop
          [uploader]="uploader"
        >
          <mat-form-field
            appearance="outline"
            (click)="fileInput.click()"
            class="w-100 mt-2"
          >
            <mat-label>Select a file</mat-label>
            <input
              matInput
              [formControl]="inputDisplayCtrl"
              type="text"
              readonly
              [value]="uploader.queue.length ? uploader.queue[0].file.name : ''"
            />
            <mat-icon
              *ngIf="uploader?.queue?.length"
              class="mr-1"
              [mimeType]="uploader.queue[0].file.type"
              matPrefix
            ></mat-icon>
            <button mat-icon-button matSuffix>
              <mat-icon svgIcon="folder-open"></mat-icon>
            </button>
            <mat-error>{{ errMsg.message }}</mat-error>
          </mat-form-field>
          <mat-progress-bar
            *ngIf="uploader?.isUploading"
            class="file-upload-progress w-100"
            color="primary"
            [mode]="uploader.progress === 100 ? 'indeterminate' : 'buffer'"
            [value]="uploader.progress"
          >
          </mat-progress-bar>
          <input
            #fileInput
            type="file"
            ng2FileSelect
            [uploader]="uploader"
            accept=".xlsx"
            style="display: none"
          />
        </div>
      </div>
      <mat-divider [vertical]="true" *ngIf="showErrors"></mat-divider>

      <div class="col" *ngIf="showErrors">
        <div id="right" class="pt-0">
          <table mat-table [dataSource]="errorsToDisplay" class="full-width">
            <ng-container matColumnDef="row">
              <th mat-header-cell *matHeaderCellDef>Row Number</th>
              <td mat-cell *matCellDef="let item">{{ item.row }}</td>
            </ng-container>
            <ng-container matColumnDef="messages">
              <th mat-header-cell *matHeaderCellDef>Validation Messages</th>
              <td mat-cell *matCellDef="let item">
                <div
                  class="container"
                  *ngFor="let message of item.messages; index as i"
                >
                  <div class="small row justify-content-center">
                    {{ i + 1 }}: {{ message }}
                  </div>
                </div>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="settings.errorTableColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let myRowData; columns: settings.errorTableColumns"
            ></tr>
          </table>
        </div>
      </div>
    </div>
    <div class="mt-3" *ngIf="showErrors"></div>
    <ng-container *ngIf="settings.showCountrySelect">
      <mat-accordion
        displayMode="flat"
        hideToggle
        matTooltip="Show Country Search"
      >
        <mat-expansion-panel #countrySearchPanel="matExpansionPanel">
          <mat-expansion-panel-header>
            <mat-panel-title>Country Search</mat-panel-title>
            <mat-panel-description>
              Search country names and codes
              <mat-icon [svgIcon]="'map-marker-question'"></mat-icon>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <app-search-locations-dialog> </app-search-locations-dialog>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </div>
  <mat-dialog-actions class="flex-row">
    <button mat-button mat-dialog-close color="primary" id="bulk-upload-cancel">
      Cancel
    </button>
    <button
      mat-raised-button
      color="warn"
      id="bulk-upload-show-errors"
      *ngIf="errorsToDisplay?.length > 0 && !showErrors"
      (click)="showErrors = true"
    >
      Errors
    </button>
    <button
      mat-raised-button
      color="accent"
      id="bulk-upload-hide-errors"
      *ngIf="errorsToDisplay?.length > 0 && showErrors"
      (click)="showErrors = false"
    >
      Hide
    </button>
    <span class="flex-grow-1"></span>
    <!-- CLASSIFY BUTTON -->
    <button
      mat-flat-button
      type="button"
      id="bulk-dialog-classify-btn"
      appClassify
      *appClassifyShow
      [(classificationModel)]="classificationModel"
      color="accent"
    >
      Classify
    </button>
    <button
      mat-raised-button
      color="primary"
      id="bulk-upload-submit"
      [disabled]="
        !uploader?.queue?.length ||
        uploader?.isUploading ||
        !hasOrg() ||
        !isFarId()
      "
      (click)="submit()"
    >
      {{ settings.submitButtonLabel }}
    </button>
  </mat-dialog-actions>
</div>

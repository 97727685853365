<form #nationalIdForm="ngForm">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <app-country-select
          #issuingCountryCode="ngModel"
          [required]="true"
          [placeholder]="'Issuing Country'"
          [(ngModel)]="value.issuingCountryCode"
          (ngModelChange)="emitChangeEvent()"
          (blur)="onTouched()"
          name="issuingCountryCode"
          id="issuingCountryCode"
          floatLabel="auto"
          [excludeUS]="true"
        ></app-country-select>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>National ID</mat-label>
          <input
            #nationalId="ngModel"
            matInput
            [required]="true"
            [(ngModel)]="value.nationalId"
            name="nationalId"
            maxlength="50"
            placeholder="National ID"
          />
          <button
            *ngIf="value.nationalId?.length"
            matTooltip="Clear"
            matSuffix
            mat-icon-button
            (click)="value.nationalId = ''"
          >
            <mat-icon>close</mat-icon>
          </button>
          <mat-error>Please enter a valid National ID</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
